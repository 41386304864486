import React from "react";
import { LocationOn, AccessTime } from "@mui/icons-material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import discount from "../../assets/discount.svg";
import ConditionalRenderer from "../../devsathan_components/ConditionalRenderer";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DateSection from "../Cards/offeringCardComponents/DateSection";
import { pushGTMEvent } from "../../utils/GTMEventComponent";
import {BOOK_PUJA_PARTICIPATE_LANDING_PAGE} from "../../utils/gtmEventConstants";

const PujaEventCard = ({ pujaEvent, onButtonClick }) => {
  const eventStatus =pujaEvent.eventType ; // pujaEvent.eventType;  
console.log("pujaEvent", pujaEvent.eventType);
const isInProgress = eventStatus === "INPROGRESS_EVENT";
const isUpcoming = eventStatus === "UPCOMING_EVENT";
const isEnded = eventStatus === "EVENT_END";

  return (
    <div className="flex flex-col justify-between w-full sm:w-[420px] max-w-lg  max-h-lg bg-custom-beige rounded-3xl overflow-hidden shadow-lg h-full">
      {/* Image Container */}
      <div>
        <div className="relative ">
          <img
            src={pujaEvent.mainSection.titleImageInfo.url}
            alt={pujaEvent.mainSection.titleImageInfo.altText}
            className="w-full h-full object-cover"
          />

          {/* Overlay Elements */}
          {pujaEvent.attendeeStatusText && pujaEvent.attendeeStatusText > 0 && (
            <div className="absolute -top-4 -left-3 bg-[#E59419] text-white px-3 py-1 rounded-full rounded-bl-none text-xs sm:text-sm flex items-center gap-1">
              <div className="flex items-center gap-1 ml-3 mt-5 mb-0  mr-2">
                <span>
                  <TrendingUpIcon fontSize="small" />{" "}
                  {pujaEvent.attendeeStatusText || 0}
                </span>
              </div>
            </div>
          )}

          <ConditionalRenderer componentKey="whatsappbutton">
            <button className="absolute top-2 right-2 bg-green-500 text-white rounded-full p-3 flex items-center justify-center">
              <WhatsAppIcon />
            </button>
          </ConditionalRenderer>

          {pujaEvent?.mainSection?.discountText && (
            <div className="absolute bottom-0 left-0 right-0 bg-[#BA1A1A] text-white text-center p-1 flex items-center justify-center rounded-b-3xl">
              <img src={discount} alt="" className="mr-1 h-5" />
              <span className="text-sm sm:text-[18px] md:text-[16px] p-1 ">
                {pujaEvent.mainSection.discountText}
              </span>
            </div>
          )}
          {!isInProgress && eventStatus && (
            <div className="absolute inset-0  bg-opacity-50 backdrop-blur-md flex items-center justify-center">
              <span className="text-white font-bold text-lg sm:text-xl">
              {isUpcoming
                  ? "Upcoming Event"
                  : isEnded
                  ? "Event Ended"
                  : eventStatus.replace("_", " ")}
              </span>
            </div>
          )}
        </div>

        {/* Content */}
        <div className="p-4 flex flex-col gap-3">
          {/* Date and Time */}

          <DateSection offering={pujaEvent?.mainSection} />

          {/* Title and Description */}
          <div>
            <h3 className="text-lg sm:text-xl font-bold text-[#3A0B01] mb-1 text-wrap">
              {pujaEvent.mainSection.name}
            </h3>
            <p className="text-[#333333] text-sm sm:text-sm line-clamp-3 text-wrap">
              {pujaEvent.mainSection.shortDescription}
            </p>
          </div>

          {/* Location */}
          <div className="flex items-start gap-1 text-gray-600 text-sm sm:text-sm">
            <LocationOn className="w-4 h-4 sm:w-5 sm:h-5 min-w-[16px] sm:min-w-[20px] text-[#333333]" />
            <span className="text-[#6C5D2F] text-wrap">
              {pujaEvent.mainSection.location.address},{" "}
              {pujaEvent.mainSection.location.city},{" "}
              {pujaEvent.mainSection.location.state}
            </span>
          </div>

          {/* Participate Button */}
        </div>
      </div>

      <div className="flex justify-end mx-4 my-4  ">
        {/* <button
            className="w-full sm:w-1/2 bg-[#FF5722] text-white py-2 rounded-xl hover:bg-[#FF5722] transition-colors duration-200"
            onClick={() => onButtonClick(pujaEvent.id)}
          >
            PARTICIPATE
          </button> */}

        <button
          className={`font-semibold p-5  py-2 rounded-full transition-transform duration-300 ${
            eventStatus === "EVENT_END"
              ? "bg-gray-400 text-gray-700 cursor-not-allowed"
              : "bg-[#FF5722] text-white hover:scale-105 hover:shadow-lg"
          }`}
          onClick={() => {
            if (isInProgress || isUpcoming) {
              onButtonClick(pujaEvent.id);
              pushGTMEvent(BOOK_PUJA_PARTICIPATE_LANDING_PAGE,
                  { event_value : pujaEvent.mainSection.name });
            }
          }}
         
        >
          {isInProgress ? "PARTICIPATE" : isUpcoming ? "UPCOMING EVENT" : "EVENT ENDED"}
        </button>
      </div>
    </div>
  );
};

export default PujaEventCard;
