import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showModal: false,
};

const showModalSlice = createSlice({
  name: "showModal",
  initialState,
  reducers: {
    toggleModal: (state) => {
      state.showModal = !state.showModal;
    },
    setModal: (state, action) => {
      state.showModal = action.payload;
    },
  },
});

export const { toggleModal, setModal } = showModalSlice.actions;
export default showModalSlice.reducer;
