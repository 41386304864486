import React from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const faqData = [
  {
    id: "1",
    question:
      "What is subscription pricing or a subscription-based pricing model?",
    answer: {
      text: "One, it attracts more customers with its lower entry point (e.g. paying $15 monthly vs. $150 in advance). Two, it offers predictable revenue for businesses. Three, it allows businesses to build stronger, more valuable relationships with its customers.",
      bullets: [
        "Attracts more customers with lower entry point",
        "Offers predictable revenue for businesses",
        "Allows businesses to build stronger, more valuable relationships with customers",
      ],
    },
  },
  {
    id: "2",
    question:
      "What is subscription pricing or a subscription-based pricing model?",
    answer: {
      text: "Sample answer for question 2",
      bullets: [],
    },
  },
  {
    id: "3",
    question:
      "What is subscription pricing or a subscription-based pricing model?",
    answer: {
      text: "Sample answer for question 3",
      bullets: [
        "Bullet point 1 for question 3",
        "Bullet point 2 for question 3",
      ],
    },
  },
  {
    id: "4",
    question:
      "What is subscription pricing or a subscription-based pricing model?",
    answer: {
      text: "Sample answer for question 4",
      bullets: [],
    },
  },
  {
    id: "5",
    question:
      "What is subscription pricing or a subscription-based pricing model?",
    answer: {
      text: "Sample answer for question 5",
      bullets: [
        "Bullet point 1 for question 5",
        "Bullet point 2 for question 5",
        "Bullet point 3 for question 5",
      ],
    },
  },
];
const FAQSection = ({ faqs }) => {
  return (
    <div className="py-2 sm:py-8 px-4">
      <div className="max-w-screen-lg mx-auto">
        <div className="text-center mb-8">
          <h2 className="text-3xl md:text-4xl lg:text-4xl text-custom-maroon font-bold mb-3">
            Frequently Asked Questions
          </h2>
          <p className="text-md md:text-lg lg:text-base text-custom-brown">
            Over 200 sacred rituals at renowned shrines offer a live Puja
            experience each month.
          </p>
        </div>

        <div className="space-y-4  ">
          {faqs.map((faq) => (
            <Accordion
              key={faq.id}
              className="overflow-hidden p-2"
              style={{
                boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
                borderRadius: "12px",
                backgroundColor: "#FDF4E8",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${faq.id}-content`}
                id={`panel${faq.id}-header`}
                className="rounded-3xl px-6 py-3"
                style={{
                  fontWeight: "600",
                  fontSize: "17px",
                  color: "#3A0B01",
                }}
              >
                {faq.question}
              </AccordionSummary>
              <AccordionDetails
                className="px-6 py-4"
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#2B1700",
                }}
              >
                <div>{faq.answer.text}</div>
                {faq.answer.bullets.length > 0 && (
                  <ul className="mt-2">
                    {faq.answer.bullets.map((bullet, index) => (
                      <li key={index} className="list-disc ml-4">
                        {bullet}
                      </li>
                    ))}
                  </ul>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
