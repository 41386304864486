import React from "react";

const PaymentProcessing = () => {
  return (
    <div className="min-h-screen bg-light-pink flex justify-center items-center pt-16">
      <div className="flex flex-col lg:flex-row items-center space-x-4 bg-custom-beige px-16 py-10 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
        {/* Modern Spinner */}
        <div className="animate-spin h-12 w-12 border-4 border-pink-300 rounded-full border-t-pink-500 border-r-pink-500"></div>

        {/* Payment Processing Text */}
        <span className="text-xl font-semibold text-gray-800 mt-4 lg:mt-0">
          Processing Payment...
        </span>
      </div>
    </div>  
  );
};

export default PaymentProcessing;
