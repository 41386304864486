import React from "react";
import { useState, useEffect, useRef } from "react";

import { useDispatch } from "react-redux";
import {
  incrementItemCount,
  decrementItemCount,
  emptyCart,
} from "../../redux/reducers/cart";
import { setPackage } from "../../redux/reducers/pujaPackage";
import EditIcon from "@mui/icons-material/Edit";
import { GETUSERDATA, CART, CREATEORDER } from "../../rest/endpoints";
import RazorpayPayment from "../Common/RazorpayPayment";

import DeleteIcon from "@mui/icons-material/Delete";
import { getAuthHeaders } from "../../utils/getHeaders";
import { apiRequest } from "../../utils/apiRequest";
import { toggleRefresh } from "../../redux/reducers/refreshTrigger";
import { pushGTMEvent } from "../../utils/GTMEventComponent";
import { BOOKING_CHECKOUT_BUTTON } from "../../utils/gtmEventConstants";

import { useSelector } from "react-redux";

const OrderDetails = ({ type, chadawa, currentCart, reload, setReload }) => {
  const offeringItemList = chadawa?.offeringItemList || [];
  const headers = getAuthHeaders();
  const [userData, setUserData] = useState({});
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const [cartItems, setCartItems] = useState(null);
  const scrollContainerRef = useRef(null);
  const [showTopShadow, setShowTopShadow] = useState(false);
  const [showBottomShadow, setShowBottomShadow] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const shouldShowIndicators = cartItems?.length > 3;
  const [formData, setFormData] = useState({
    fullName: "",
    whatsappNumber: "",
    names: [], // For additional names
  });
  const [addressDetails, setAddressDetails] = useState({
    address: "",
    city: "",
    state: "",
    pincode: "",
  });
  const [errors, setErrors] = useState({});
  const selectedPackagetype = useSelector(
    (state) => state.pujaPackage.selectedPackage
  );
  const maxUserAllowed = chadawa?.pujaPackageList?.find(
    (item) => item.id === selectedPackagetype.id
  )?.maxUserAllowed;

  const storageKey = `prasad_${chadawa?.id}_${chadawa?.prasadInfo?.id}`;
  const [stayUpdated, setStayUpdated] = useState(false);

  useEffect(() => {
    if (!storageKey) return; // Ensure storageKey is valid before accessing localStorage

    const savedValue = localStorage.getItem(storageKey);
    if (savedValue !== null) {
      setStayUpdated(JSON.parse(savedValue));
    }
  }, [storageKey]); // Run when storageKey changes

  const options =
    chadawa?.pujaPackageList?.map((pkg) => ({
      id: pkg.id,
      name: pkg.name,
    })) || [];

  useEffect(() => {
    if (maxUserAllowed > 0) {
      setFormData((prev) => ({
        ...prev,
        names: Array(maxUserAllowed - 1).fill(""),
      }));
    }
  }, [maxUserAllowed]);
  useEffect(() => {
    if (userData) {
      setFormData((prev) => ({
        ...prev,
        fullName: userData?.name || "",
        whatsappNumber: userData?.phone || "",
      }));
    }
  }, [userData]);
  useEffect(() => {
    const fetchData = async () => {
      const cartData = currentCart;
      const existingCartItems = cartData?.itemList || [];

      // Merge offeringItemList with cartItems and add imageInfo
      const mergedItems = (offeringItemList || []).map((offeringItem) => {
        const existingItem = existingCartItems.find(
          (cartItem) => cartItem.itemId === offeringItem.id
        );
        return existingItem
          ? { ...existingItem, imageInfo: offeringItem.imageInfo } // Retain cart data but update imageInfo
          : {
              ...offeringItem,
              itemId: offeringItem.id,
              quantity: 0,
              imageInfo: offeringItem.imageInfo,
            }; // Add new item with quantity 0 and imageInfo
      });

      setCartItems(() => {
        return mergedItems;
      });
    };

    if (offeringItemList.length > 0) {
      fetchData();
    }
  }, [offeringItemList]);
  useEffect(() => {
    const fetchUserData = async () => {
      const response = await apiRequest({
        url: GETUSERDATA,
        method: "GET",
        headers,
      });
      setUserData(response?.data);
    };
    fetchUserData();
  }, []);
  useEffect(() => {
    if (userData) {
      setFormData((prev) => ({
        ...prev,
        fullName: userData?.name || "",
        whatsappNumber: userData?.phone || "",
      }));
    }
  }, [userData]);
  useEffect(() => {
    const element = scrollContainerRef.current;
    if (element && shouldShowIndicators) {
      handleScroll();
      element.addEventListener("scroll", handleScroll);
      return () => element.removeEventListener("scroll", handleScroll);
    }
  }, [shouldShowIndicators]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // Mark first render as done
      return; // Skip the API call on initial render
    }

    const postData = async () => {
      await apiRequest({
        url: CART,
        method: "POST",
        data: {
          parentId: chadawa?.id,
          type: type,
          itemId: chadawa?.prasadInfo?.id,
          subType: "PRASAD",
          quantity: stayUpdated ? 1 : 0, // 1 if checked, 0 if unchecked
        },
        headers,
      });

      setReload(!reload);
      dispatch(toggleRefresh());
      const storageKey = `prasad_${chadawa?.id}_${chadawa?.prasadInfo?.id}`;
      localStorage.setItem(storageKey, JSON.stringify(stayUpdated));
    };

    postData();
  }, [stayUpdated]);

  const handleIncrement = async (id, amount, itemName, imageInfo) => {
    const currentQuantity =
      cartItems.find((item) => item.itemId === id)?.quantity || 0;
    const newQuantity = currentQuantity + 1;
    await apiRequest({
      url: CART,
      method: "POST",
      data: {
        type: "OFFERING",
        parentId: chadawa?.id,
        subType: "OFFERING_ITEM",
        itemId: id,
        quantity: newQuantity,
      },
      headers,
    });

    setReload(!reload);
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.itemId === id ? { ...item, quantity: newQuantity } : item
      )
    );

    dispatch(
      incrementItemCount({
        id,
        amount,
        itemName,
        imageInfo,
        chadawaId: chadawa?.id,
      })
    );
  };
  const handleDecrement = async (id, amount, itemName, imageInfo) => {
    const currentQuantity =
      cartItems.find((item) => item.itemId === id)?.quantity || 0;
    const newQuantity = currentQuantity - 1;

    await apiRequest({
      url: CART,
      method: "POST",
      data: {
        type: "OFFERING",
        parentId: chadawa?.id,
        subType: "OFFERING_ITEM",
        itemId: id,
        quantity: newQuantity,
      },
      headers,
    });

    setReload(!reload);
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.itemId === id ? { ...item, quantity: newQuantity } : item
      )
    );

    dispatch(
      decrementItemCount({
        id,
        amount,
        itemName,
        imageInfo,
        chadawaId: chadawa?.id,
      })
    );
  };
  const handleScroll = () => {
    if (!shouldShowIndicators) return;

    const element = scrollContainerRef.current;
    if (element) {
      const { scrollTop, scrollHeight, clientHeight } = element;

      setShowTopShadow(scrollTop > 0);

      // Fix: Allow a small tolerance (2px) to prevent shadow persisting unnecessarily
      setShowBottomShadow(
        Math.abs(scrollTop + clientHeight - scrollHeight) > 2
      );
    }
  };
  const handleInputChange = (index, e) => {
    if (index === null) {
      // Handle main form fields
      setFormData((prev) => ({
        ...prev,
        [e.target.id]: e.target.value,
      }));
    } else {
      // Handle dynamic name fields
      setFormData((prev) => {
        const newNames = [...prev.names];
        newNames[index] = e.target.value;
        return { ...prev, names: newNames };
      });
    }
  };
  const handleAddressChange = (e) => {
    setAddressDetails((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };
  const handleStayUpdatedChange = (e) => {
    setStayUpdated(e.target.checked);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};

    // Validate main full name
    if (!formData.fullName.trim()) {
      newErrors.fullName = "Full Name is required.";
    } else if (!/^[A-Za-z.\s]+$/.test(formData.fullName.trim())) {
      newErrors.fullName =
        "Full Name should contain only letters, spaces, and periods.";
    }

    // WhatsApp validation
    if (!formData.whatsappNumber.trim()) {
      newErrors.whatsappNumber = "WhatsApp Number is required.";
    } else if (/[^0-9]/.test(formData.whatsappNumber)) {
      newErrors.whatsappNumber = "WhatsApp Number must contain only digits.";
    } else if (!/^\d{10}$/.test(formData.whatsappNumber)) {
      newErrors.whatsappNumber = "WhatsApp Number must be exactly 10 digits.";
    }

    // Address fields validation if Prasad is selected
    if (stayUpdated) {
      if (!addressDetails.address.trim()) {
        newErrors.address = "Address is required.";
      }
      if (!addressDetails.city.trim()) {
        newErrors.city = "City is required.";
      }
      if (!addressDetails.state.trim()) {
        newErrors.state = "State is required.";
      }
      if (!addressDetails.pincode.trim()) {
        newErrors.pincode = "Pincode is required.";
      } else if (!/^\d{6}$/.test(addressDetails.pincode)) {
        newErrors.pincode = "Pincode must be exactly 6 digits.";
      }
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const requestData = {
        cartId: "", // Assuming cartId is empty as per API request
        userInfo: {
          name: formData.fullName,
          whatsappNumber: formData.whatsappNumber,
          gotra: formData.gotra || "",
          additionalNames: formData.names || "", // Sending additional names array
        },
        deliveryInfo: stayUpdated
          ? {
              location: {
                address: addressDetails.address,
                city: addressDetails.city,
                state: addressDetails.state,
                pincode: addressDetails.pincode,
              },
            }
          : undefined, // Send `deliveryInfo` only if Prasad is selected
      };

      pushGTMEvent(BOOKING_CHECKOUT_BUTTON, {
        event_value: chadawa?.mainSection?.name,
        cart_items: cartItems,
      });

      const response = await apiRequest({
        url: CREATEORDER,
        method: "POST",
        data: requestData,
        headers,
      });

      const orderId = response?.data.orderId;
      setOrderId(orderId);
      dispatch(emptyCart());
    }
  };
  const handleEditClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleOptionClick = async (option) => {
    setIsDropdownOpen(false);

    await apiRequest({
      url: CART, // API endpoint
      method: "POST",
      data: {
        parentId: chadawa?.id,
        type: type,
        subType: "PUJA_PACKAGE",
        quantity: 1,
        itemId: option.id,
      },
      headers,
    });

    dispatch(setPackage(option));
    setReload(!reload);
  };

  return (
    <div className="md:w-[400px] w-full md:min-h-fit pt-6 md:pt-0 bg-custom-beige border-l-2 border-gray-300 md:rounded-r-[24px]">
      <div className="flex flex-col items-center justify-center lg:border-b-2 lg:border-gray-300 h-[65px]">
        <div className="flex flex-row items-center justify-center pt-2 mx-10 px-10">
          <div className="flex justify-center items-center">
            <div className="w-5 h-5 bg-custom-orange text-white flex justify-center items-center transform rotate-45">
              <span className="transform -rotate-45 text-sm font-bold">1</span>
            </div>
          </div>
          <div className="w-20 h-0 border-dotted border-t-2 border-custom-orange mx-2"></div>
          <div className="flex justify-center items-center">
            <div className="w-5 h-5 border-2 border-custom-orange text-custom-orange flex justify-center items-center transform rotate-45 bg-inherit">
              <span className="transform -rotate-45 text-sm font-bold">2</span>
            </div>
          </div>

          <div className="w-20 h-0 border-dotted border-t-2 border-custom-orange mx-2"></div>
          <div className="flex justify-center items-center">
            <div className="w-5 h-5 border-2 border-custom-orange text-custom-orange flex justify-center items-center transform rotate-45 bg-inherit">
              <span className="transform -rotate-45 text-sm font-bold">3</span>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center pt-2 px-11 space-x-12 max-w-[400px]">
          <p className="text-xs">Basic Details</p>
          <p className="text-xs">Payment</p>
          <p className="text-xs">Confirmation</p>
        </div>
      </div>

      {/* Booking Details */}
      <div className="p-6">
        <div className="container mx-auto">
          {chadawa?.offeringItemList && (
            <div className="flex flex-col md:flex-row gap-8 bg-custom-light-beige rounded-[16px] my-6">
              <div className="flex-grow">
                <div className="relative">
                  {/* Top Shadow Overlay */}
                  {shouldShowIndicators && showTopShadow && (
                    <div
                      className="rounded-t-xl absolute top-0 left-0 right-0 h-20 bg-gradient-to-b from-custom-beige to-transparent pointer-events-none z-10 backdrop-blur-md -mt-1"
                      style={{
                        maskImage:
                          "linear-gradient(to bottom, black, transparent)",
                      }}
                    />
                  )}

                  {/* Scrollable Content */}
                  <div
                    ref={scrollContainerRef}
                    className="max-h-[245px] overflow-y-auto space-y-2 px-4 py-3"
                    style={{
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                    }}
                  >
                    {cartItems !== null &&
                      cartItems?.map((item) => {
                        const imageInfo = item.imageInfo || {};

                        return (
                          <div
                            key={item.id}
                            className="bg-inherit w-full pb-1 rounded-lg"
                          >
                            <div className="flex items-center gap-4">
                              {/* Image */}
                              <div className="w-12 h-12 rounded-xl overflow-hidden flex-shrink-0 mt-1">
                                <img
                                  src={imageInfo.url}
                                  alt={item.itemName}
                                  className="w-full h-full object-cover"
                                />
                              </div>

                              {/* Name (left side) */}
                              <div className="flex flex-col flex-grow">
                                <h2 className="text-xs font-medium text-custom-maroon line-clamp-2">
                                  {item.name}
                                </h2>
                              </div>

                              {/* Quantity Controls & Price (aligned on right side, with price fixed width) */}
                              <div className="flex-shrink-0 flex items-center">
                                {/* Quantity Controls */}
                                {item.quantity === 0 ? (
                                  <button
                                    className="py-1 px-4 text-xs text-custom-orange font-semibold border-2 border-custom-orange rounded-full hover:bg-custom-orange hover:text-white transition mr-1"
                                    onClick={() =>
                                      handleIncrement(
                                        item.itemId,
                                        item.amount,
                                        item.name,
                                        imageInfo,
                                        item.chadawaId
                                      )
                                    }
                                  >
                                    + Add
                                  </button>
                                ) : (
                                  <div className="flex items-center border-2 border-custom-orange rounded-full mr-2">
                                    <button
                                      className="text-custom-orange font-semibold hover:text-white hover:bg-custom-orange w-6 h-6 rounded-full transition-all flex items-center justify-center"
                                      onClick={() =>
                                        handleDecrement(
                                          item.itemId,
                                          item.amount,
                                          item.name,
                                          imageInfo,
                                          item.chadawaId
                                        )
                                      }
                                    >
                                      {item.quantity === 1 ? (
                                        <DeleteIcon fontSize="extra-small" />
                                      ) : (
                                        "-"
                                      )}
                                    </button>
                                    <span className="px-1 text-sm font-semibold">
                                      {item.quantity}
                                    </span>
                                    <button
                                      className="text-custom-orange font-semibold hover:text-white hover:bg-custom-orange w-6 h-6 rounded-full transition-all flex items-center justify-center"
                                      onClick={() =>
                                        handleIncrement(
                                          item.itemId,
                                          item.amount,
                                          item.name,
                                          imageInfo,
                                          item.chadawaId
                                        )
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                )}
                                {/* Fixed-width Price */}
                                <div className="flex-shrink-0 min-w-[45px] text-right">
                                  <p className="text-sm font-bold text-custom-maroon whitespace-nowrap">
                                    ₹{item.itemAmount || item.amount}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>

                  {/* Bottom Shadow Overlay */}
                  {shouldShowIndicators && showBottomShadow && (
                    <div
                      className="rounded-b-xl absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-custom-beige to-transparent pointer-events-none z-10 backdrop-blur-md -mb-1"
                      style={{
                        maskImage:
                          "linear-gradient(to top, black, transparent)",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          {chadawa?.pujaPackageList && (
            <div className="relative mb-4">
              <label className="block text-sm font-medium text-custom-maroon pt-4">
                Package Type
              </label>
              <div className="mt-1 flex items-center justify-between border border-gray-300 rounded-md p-2 w-full">
                <span className="text-custom-orange">
                  {selectedPackagetype.name}
                </span>
                <button
                  type="button"
                  className="text-orange-500 hover:underline"
                  onClick={handleEditClick}
                >
                  <EditIcon />
                </button>
              </div>

              {/* Dropdown */}
              <div
                className={`absolute z-10 mt-2 w-full border border-gray-300 rounded-md shadow-lg bg-custom-beige overflow-hidden transition-all duration-300 ${
                  isDropdownOpen ? "max-h-40 opacity-100" : "max-h-0 opacity-0"
                }`}
              >
                {options.map((option) => (
                  <div
                    key={option.id}
                    className="px-4 py-2 text-gray-700 hover:bg-dark-pink cursor-pointer"
                    onClick={() => handleOptionClick(option)}
                  >
                    {option.name}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <h3 className="text-lg font-semibold">Enter your basic detail</h3>
        <form className="mt-4 space-y-4" onSubmit={handleSubmit}>
          {/* Full Name Field */}
          <div>
            <label
              htmlFor="fullName"
              className="block text-sm font-medium text-custom-maroon"
            >
              Full Name
            </label>
            <input
              type="text"
              id="fullName"
              className={`mt-2 py-2 px-4 block w-full md:w-[350px] rounded-md border-2 ${
                errors.fullName ? "border-red-500" : "border-custom-orange"
              }`}
              placeholder="Enter your full name"
              value={formData.fullName}
              onChange={(e) => handleInputChange(null, e)}
            />
            {errors.fullName && (
              <p className="text-red-500 text-sm mt-1">{errors.fullName}</p>
            )}
          </div>

          {/* WhatsApp Number Field */}
          <div>
            <label
              htmlFor="whatsappNumber"
              className="block text-sm font-medium text-custom-maroon"
            >
              WhatsApp Number
            </label>
            <input
              type="text"
              id="whatsappNumber"
              className={`mt-2 py-2 px-4 block w-full md:w-[350px] rounded-md border-2 ${
                errors.whatsappNumber
                  ? "border-red-500"
                  : "border-custom-orange"
              }`}
              placeholder="Enter your WhatsApp number"
              value={formData.whatsappNumber}
              onChange={(e) => handleInputChange(null, e)}
            />
            {errors.whatsappNumber && (
              <p className="text-red-500 text-sm mt-1">
                {errors.whatsappNumber}
              </p>
            )}
          </div>
          {/* Gotra Field */}
          <div>
            <label
              htmlFor="gotra"
              className="block text-sm font-medium text-custom-maroon"
            >
              Gotra
            </label>
            <input
              type="text"
              id="gotra"
              className={`mt-2 py-2 px-4 block w-full md:w-[350px] rounded-md border-2 ${
                errors.gotra ? "border-red-500" : "border-custom-orange"
              }`}
              placeholder="Enter your Gotra"
              value={formData.gotra}
              onChange={(e) => handleInputChange(null, e)}
            />
            {errors.gotra && (
              <p className="text-red-500 text-sm mt-1">{errors.gotra}</p>
            )}
          </div>
          {/* Stay Updated Checkbox */}
          {chadawa?.prasadInfo && (
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="stayUpdated"
                checked={stayUpdated}
                onChange={handleStayUpdatedChange}
                className="rounded border-gray-300 text-orange-500 focus:ring-orange-500"
              />
              <label
                htmlFor="stayUpdated"
                className="text-base font-semibold text-custom-maroon"
              >
                Add Prasad?
              </label>
            </div>
          )}
          {/* Address Field with Animation */}
          <div
            className={`transition-all duration-500 ease-in-out ${
              stayUpdated ? "max-h-[400px] opacity-100" : "max-h-0 opacity-0"
            } overflow-hidden`}
          >
            <div className="transform transition-transform duration-500">
              {/* Address */}
              <div className="mb-4">
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-custom-maroon"
                >
                  Address
                </label>
                <input
                  type="text"
                  id="address"
                  className={`mt-2 py-2 px-4 block w-full md:w-[350px] rounded-md border-2 ${
                    errors.address ? "border-red-500" : "border-custom-orange"
                  }`}
                  placeholder="Enter your address"
                  value={addressDetails.address}
                  onChange={handleAddressChange}
                />
                {errors.address && (
                  <p className="text-red-500 text-sm mt-1">{errors.address}</p>
                )}
              </div>

              {/* City */}
              <div className="mb-4">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-custom-maroon"
                >
                  City
                </label>
                <input
                  type="text"
                  id="city"
                  className={`mt-2 py-2 px-4 block w-full md:w-[350px] rounded-md border-2 ${
                    errors.city ? "border-red-500" : "border-custom-orange"
                  }`}
                  placeholder="Enter your city"
                  value={addressDetails.city}
                  onChange={handleAddressChange}
                />
                {errors.city && (
                  <p className="text-red-500 text-sm mt-1">{errors.city}</p>
                )}
              </div>

              {/* State */}
              <div className="mb-4">
                <label
                  htmlFor="state"
                  className="block text-sm font-medium text-custom-maroon"
                >
                  State
                </label>
                <input
                  type="text"
                  id="state"
                  className={`mt-2 py-2 px-4 block w-full md:w-[350px] rounded-md border-2 ${
                    errors.state ? "border-red-500" : "border-custom-orange"
                  }`}
                  placeholder="Enter your state"
                  value={addressDetails.state}
                  onChange={handleAddressChange}
                />
                {errors.state && (
                  <p className="text-red-500 text-sm mt-1">{errors.state}</p>
                )}
              </div>

              {/* Pincode */}
              <div>
                <label
                  htmlFor="pincode"
                  className="block text-sm font-medium text-custom-maroon"
                >
                  Pincode
                </label>
                <input
                  type="text"
                  id="pincode"
                  className={`mt-2 py-2 px-4 block w-full md:w-[350px] rounded-md border-2 ${
                    errors.pincode ? "border-red-500" : "border-custom-orange"
                  }`}
                  placeholder="Enter your pincode"
                  value={addressDetails.pincode}
                  onChange={handleAddressChange}
                />
                {errors.pincode && (
                  <p className="text-red-500 text-sm mt-1">{errors.pincode}</p>
                )}
              </div>
            </div>
          </div>
          {/* Dynamic Full Name Inputs */}
          {type === "PUJA" && (
            <div>
              {formData.names.map((_, index) => (
                <div key={index} className="mb-4">
                  <label
                    htmlFor={`fullName-${index}`}
                    className="block text-sm font-medium text-custom-maroon"
                  >
                    Additional Name {index + 1}
                  </label>
                  <input
                    type="text"
                    id={`fullName-${index}`}
                    className={`mt-2 py-2 px-4 block w-full lg:w-[350px] rounded-md border-2 ${
                      errors[`fullName-${index}`]
                        ? "border-red-500"
                        : "border-custom-orange"
                    }`}
                    placeholder={`Enter Additional name ${index + 1}`}
                    value={formData.names[index] || ""}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                  {errors[`fullName-${index}`] && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors[`fullName-${index}`]}
                    </p>
                  )}
                </div>
              ))}
            </div>
          )}
          {/* Submit Button */}
          <div className="w-full sm:h-[9rem] flex justify-end items-end">
            <button
              type="submit"
              className="w-full bg-orange-500 text-white rounded-md py-2 font-semibold hover:bg-orange-600"
            >
              Next
            </button>
          </div>
        </form>
        {orderId && <RazorpayPayment orderId={orderId} />}
      </div>
    </div>
  );
};

export default OrderDetails;
