import React from "react";

const SlideIndicator = ({ testimonials, currentSlide }) => {
  return (
    <div>
      <div className="flex justify-center gap-2 sm:hidden">
        {testimonials.map((_, index) => (
          <span
            key={index}
            className={`w-3 h-3 rounded-full transition-all duration-300 ${
              currentSlide === index
                ? "bg-custom-maroon scale-125"
                : "bg-gray-300"
            }`}
          ></span>
        ))}
      </div>
      <div className="pr-8 hidden sm:flex justify-end gap-2">
        <div className="flex space-x-2">
          {[...Array(3)].map((_, index) => (
            <div
              key={index}
              className="w-2 h-2 rounded-full bg-custom-maroon scale-125"
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SlideIndicator;
