import React from "react";

const BenefitsOfChadawa = ({ particularChadawa }) => {
  const benefitsList = particularChadawa.benefitsDataList || [];
  return (
    <div className="bg-[#F8BB71] pt-2 pb-10 px-4 sm:px-16 h-fit">
      <div className="flex flex-col sm:flex-row sm:space-y-8 space-y-0 sm:space-x-4 px-4">
        {/* Left Section */}
        <div className="sm:max-w-[40%] w-full pl-3 pb-8 sm:pb-14 pt-8 sm:pt-14">
          <h2 className="text-2xl lg:text-[2.6vw] md:text-[2vw] font-bold font-poppins text-custom-maroon">
            <span className="pr-2">🙏</span> Benefits of Chadawa
          </h2>
          <p className="font-poppins font-normal text-base sm:text-lg pt-4 sm:pt-5">
            Discover how Devsthan transforms your spiritual journey with trusted
            guidance, live rituals, sacred prasad delivery, and access to
            India's most revered temples—anytime, anywhere.
          </p>
        </div>

        {/* Right Section */}
        <div className="w-full px-4 sm:pl-16 h-fit">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6 mt-5 mb-5">
            {benefitsList.map((benefit) => (
              <div
                key={benefit.id}
                className="h-auto rounded-[32px] bg-[#FDF4E8] p-5 min-h-[9rem]"
              >
                <h1 className="font-poppins font-semibold text-lg sm:text-[1.2rem] text-custom-maroon">
                  {benefit.name}
                </h1>
                <p className="font-poppins font-medium text-sm sm:text-[0.9rem] pt-2 text-custom-brown">
                  {benefit.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefitsOfChadawa;
