import React, { useState, useEffect, useRef } from "react";
import TestimonialList from "./testimonialCardComponents/TestimonalList";
import SlideIndicator from "./testimonialCardComponents/SlideIndicator";

const TestimonialCard = ({ testimonials }) => {
  const sliderRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [initialScrollLeft, setInitialScrollLeft] = useState(0);
  const [visibleItems, setVisibleItems] = useState(new Set());
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setVisibleItems((prev) => {
            const updatedSet = new Set(prev);
            if (entry.isIntersecting) {
              updatedSet.add(entry.target.dataset.index);
            } else {
              updatedSet.delete(entry.target.dataset.index);
            }
            return updatedSet;
          });
        });
      },
      {
        root: sliderRef.current,
        threshold: 0.2,
      }
    );

    const cards = document.querySelectorAll(".testimonial-card");
    cards.forEach((card) => observer.observe(card));

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (sliderRef.current) {
      const index = Math.round(
        sliderRef.current.scrollLeft / sliderRef.current.offsetWidth
      );
      setCurrentSlide(index);
    }
  }, [sliderRef.current?.scrollLeft]);

  const handleDragStart = (clientX) => {
    setIsDragging(true);
    setDragStartX(clientX - sliderRef.current.offsetLeft);
    setInitialScrollLeft(sliderRef.current.scrollLeft);
    sliderRef.current.style.scrollBehavior = "auto";
  };

  const handleDragMove = (clientX) => {
    if (!isDragging) return;
    const x = clientX - sliderRef.current.offsetLeft;
    const distanceDragged = x - dragStartX;
    sliderRef.current.scrollLeft = initialScrollLeft - distanceDragged;
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  
    if (isSmallScreen && sliderRef.current) {
      const slideWidth = sliderRef.current.offsetWidth;
      const currentScroll = sliderRef.current.scrollLeft;
      
      // Detect swipe direction based on drag distance
      const dragDistance = currentScroll - initialScrollLeft;
      
      let newIndex = currentSlide;
      
      if (Math.abs(dragDistance) > slideWidth * 0.00001) {
        // Move to the next or previous slide based on drag direction
        newIndex = dragDistance > 0 ? currentSlide + 1 : currentSlide - 1;
      }
  
      // Ensure newIndex stays within bounds
      newIndex = Math.max(0, Math.min(newIndex, testimonials.length - 1));
  
      // Smoothly scroll to the determined slide
      sliderRef.current.scrollTo({
        left: newIndex * slideWidth,
        behavior: "smooth",
      });
  
      setCurrentSlide(newIndex);
    }
  };
  

  return (
    <div>
      <TestimonialList
        testimonials={testimonials}
        visibleItems={visibleItems}
        sliderRef={sliderRef}
        handleDragStart={handleDragStart}
        handleDragMove={handleDragMove}
        handleDragEnd={handleDragEnd}
      />
      <SlideIndicator testimonials={testimonials} currentSlide={currentSlide} />
    </div>
  );
};

export default TestimonialCard;
