import React from "react";
import { useState, useEffect } from "react";

const BenefitsOfChadawa = ({ pooja }) => {
  const benefitsData = pooja.benefitsSection.benefitsDataList;

  return (
    <div className="bg-[#F8BB71] pt-10 pb-8 px-4 sm:px-16 h-fit">
      <div className="flex flex-col sm:flex-row sm:space-y-8 space-y-0 sm:space-x-4 px-4">
        {/* Left Section */}
        <div className="sm:max-w-[40%] w-full pl-3 sm:pb-14 sm:pt-14">
          <h2 className="text-2xl md:text-3 xl lg:text-4xl text-custom-maroon font-bold font-poppins">
            <span className="pr-2">🙏</span> Benefits of Puja
          </h2>
          <p className="text-md md:text-lg lg:text-base pt-4 sm:pt-5">
            Discover how Devsthan transforms your spiritual journey with trusted
            guidance, live rituals, sacred prasad delivery, and access to
            India's most revered temples—anytime, anywhere.
          </p>
        </div>

        {/* Right Section */}
        <div className="w-full px-4 sm:pl-16 h-fit">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6 mt-5 mb-5">
            {benefitsData.map((benefit, index) => (
              <div
                key={index}
                className="h-auto rounded-[32px] bg-[#FDF4E8] p-4 min-h-[2rem]"
              >
                <h1 className="font-poppins font-semibold text-lg sm:text-[1.5rem] text-custom-maroon">
                  {benefit.name}
                </h1>
                <p className="font-poppins font-medium text-sm sm:text-[1.1rem] pt-2 text-custom-brown">
                  {benefit.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefitsOfChadawa;
