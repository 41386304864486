import React from "react";
import CartCard from "../Cards/CartCard";

const Cart = ({ chadawaId, items }) => {
  return (
    <div className="sm:px-20 px-10 py-4">
      <div>
        <h1 className="flex justify-center font-bold text-3xl md:text-4xl lg:text-4xl text-custom-maroon pb-8">
          Chadawa Items
        </h1>
      </div>
      <div className="flex items-center justify-center h-full">
        <CartCard items={items} chadawaId={chadawaId} />
      </div>
    </div>
  );
};

export default Cart;
