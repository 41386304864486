import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  refresh: false, // This state will toggle to trigger re-fetch
};

const refreshTriggerSlice = createSlice({
  name: "refreshTrigger",
  initialState,
  reducers: {
    toggleRefresh: (state) => {
      state.refresh = !state.refresh; // Toggle the refresh flag
    },
  },
});

export const { toggleRefresh } = refreshTriggerSlice.actions;
export default refreshTriggerSlice.reducer;
