import React, { useState } from "react";

const MenuItem = ({ icon: Icon, title, subtitle, isActive, onClick }) => (
  <div
    onClick={onClick}
    className={`flex items-start shadow-md gap-3 p-4 rounded-lg cursor-pointer transition-all duration-300 ${
      isActive
        ? "bg-dark-pink shadow-xl "
        : "bg-custom-beige hover:bg-custom-light-beige"
    }`}
  >
    {/* <div className="text-[#ff5c35] mt-1">
      <Icon fontSize="small" />
    </div> */}
    <div>
      <h3 className="font-medium text-[#3A0B01]">{title}</h3>
      <p className="text-sm text-[#6C5D2F]">{subtitle}</p>
    </div>
  </div>
);

const DevsthanWorks = () => {
  const [activeItem, setActiveItem] = useState(0);

  const menuItems = [
    {
      icon: "🚩",
      title: "🚩Select Puja/Chadawa Seva",
      subtitle: "Choose your desired ritual.",
      image:
        "https://assets-manager-devsthan.s3.ap-south-1.amazonaws.com/how_devsthan_works/select_puja_or_chadawa.webp",
    },

    {
      // icon: InfoIcon,
      title: "🚩Select Package & Offerings",
      subtitle: "Pick a package and offerings.",
      image:
        "https://assets-manager-devsthan.s3.ap-south-1.amazonaws.com/how_devsthan_works/select_package_and_offerings.webp",
    },
    {
      // icon: VideocamIcon,
      title: "🚩Share Name & Gotra",
      subtitle: "Provide details for booking.",
      image:
        "https://assets-manager-devsthan.s3.ap-south-1.amazonaws.com/how_devsthan_works/share_name_and_gotra.webp",
    },
    {
      // icon: LocalShippingIcon,
      title: "🚩Receive Prasad & Video",
      subtitle: "Get Prasad and Puja recording.",
      image:
        "https://assets-manager-devsthan.s3.ap-south-1.amazonaws.com/how_devsthan_works/receive_prasad_and_video.webp",
    },
    {
      // icon: LocalShippingIcon,
      title: "🚩Experience Divine Blessings",
      subtitle: "Feel spiritual fulfillment.",
      image:
        "https://assets-manager-devsthan.s3.ap-south-1.amazonaws.com/how_devsthan_works/experience_divine_blessings.webp",
    },
  ];

  return (
    <div className="bg-inherit p-8 sm:pt-12 rounded-3xl">
      <div className=" mx-auto">
        <div className="text-center mb-6 sm:mb-12">
          <h2 className="text-4xl md:text-4xl lg:text-4xl text-custom-maroon font-bold mb-2">
            How Devsthan Works
          </h2>
          <p className="text-md md:text-lg lg:text-base text-custom-brown">
            Rituals performed by highly trained vedic priests
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left Menu */}
          <div className="space-y-4">
            {menuItems.map((item, index) => (
              <MenuItem
                key={index}
                // icon={item.icon}
                title={item.title}
                subtitle={item.subtitle}
                isActive={activeItem === index}
                onClick={() => setActiveItem(index)}
              />
            ))}
          </div>

          {/* Right Image */}
          <div className="relative h-[200px] md:h[350px] lg:h-[445px] rounded-3xl overflow-hidden">
            {menuItems.map((item, index) => (
              <div
                key={index}
                className={`absolute inset-0 transition-opacity duration-500 ${
                  activeItem === index
                    ? "opacity-100"
                    : "opacity-0 pointer-events-none"
                }`}
              >
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full h-full object-fixed rounded-3xl"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevsthanWorks;
