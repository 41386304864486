import React, { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { TRANSACTIONDETAILS } from "../../rest/endpoints";
import { toast } from "react-toastify";
import { apiRequest } from "../../utils/apiRequest";
import { getAuthHeaders } from "../../utils/getHeaders";

import { trackMetaEvent } from "../../utils/metaPixel";
import { pushGTMEvent } from "../../utils/GTMEventComponent";
import {PAYMENT_STATUS} from "../../utils/gtmEventConstants";
const styles = `
@keyframes wave {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: scale(3);
    opacity: 0.3;
  }
}

.wave-circle {
  animation: wave 1.5s linear infinite;
}

.wave-circle-2 {
  animation: wave 1.5s linear infinite;
  animation-delay: 0.5s;
}

.wave-circle-3 {
  animation: wave 1.5s linear infinite;
  animation-delay: 1s;
}
`;

const SuccessIcon = () => (
  <div className="relative w-20 h-20 flex items-center justify-center">
    {/* Add style tag for animations */}
    <style>{styles}</style>

    {/* Smooth wave effect with adjusted spacing */}
    <div className="absolute w-full h-full rounded-full bg-green-100/30 wave-circle"></div>
    <div className="absolute w-full h-full rounded-full bg-green-100/30 wave-circle-2"></div>
    <div className="absolute w-full h-full rounded-full bg-green-100/30 wave-circle-3"></div>

    {/* Inner static glowing effect */}
    <div className="absolute w-20 h-20 rounded-full bg-green-100/50 shadow-md"></div>

    {/* Check Icon Circle */}
    <div className="relative w-16 h-16 rounded-full bg-green-500 flex items-center justify-center shadow-lg">
      <CheckIcon sx={{ color: "white", fontSize: 32 }} />
    </div>
  </div>
);

// Custom Labels Mapping
const customLabels = {
  orderId: "Order ID",
  orderDisplayName: "Order Status",
  totalAmount: "Total Amount",
  paymentId: "Payment ID",
  paymentMethod: "Payment Method",
};

const PaymentSuccess = () => {
  const headers = getAuthHeaders();
  const navigate = useNavigate();
  const OrderIdparams = new URLSearchParams(window.location.search);
  const OrderId = OrderIdparams.get("orderId");
  const [transactionDetails, setTransactionDetails] = useState({});
  const [countdown, setCountdown] = useState(20);

  useEffect(() => {
    const fetchData = async () => {
      
      try {
        const result = await apiRequest({
          url: `${TRANSACTIONDETAILS}${OrderId}`,
          method: "GET",
          headers,
        });
        const data = result;
        console.log("Hello data", data);

        if (data) {
          pushGTMEvent(PAYMENT_STATUS, {
            event_value: "PAYMENT_SUCCESS",
            order_id: OrderId,
            total_amount: data?.data?.totalAmount,
          });
          
          setTransactionDetails(data.data);
          trackMetaEvent("PURCHASE", {currency: "INR", value: data?.data?.totalAmount});
        } else {
          console.error("Transaction data is not available");
          toast.error("Transaction data is missing.");
        }
      } catch (error) {
        // Handle errors
        console.error(
          error.response?.message || error.message || "Something went wrong"
        );
        toast.error("Failed to fetch transaction details. Please try again.");
      }
    };

    fetchData();
  }, [OrderId]);

  const gotohomepage = () => {
    navigate("/");
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    const autoNavigate = setTimeout(() => {
      navigate("/");
    }, 20000);

    return () => {
      clearInterval(timer);
      clearTimeout(autoNavigate);
    };
  }, [navigate]);

  return (
    <div className="min-h-screen bg-[#FDF4E8] flex items-center justify-center p-4 md:p-8 mt-16">
      <div className="max-w-lg w-full space-y-6">
        {/* Main Card */}
        <div className="bg-white rounded-xl shadow-lg p-2 sm:p-8">
          <div className="bg-white rounded-xl shadow-lg p-2 sm:p-8">
            {/* Success Icon Section */}
            <div className="flex flex-col items-center text-center mb-8">
              <div className="mb-6">
                <SuccessIcon />
              </div>

              <h1 className="text-3xl font-bold text-[#333333] mb-2">
                Payment Successful!
              </h1>

              <p className="text-[#6C5D2F] text-lg">
                {transactionDetails?.paymentDetails?.message}
              </p>
            </div>

            {/* Transaction Details Card */}
            <div className="bg-[#F1E3D6] rounded-lg p-4 mb-8 text-[11px] md:text-[13px]">
              {/* <h2 className="text-[#2B1700] font-semibold mb-4 ">OrderId {transactionDetails.OrderId}</h2> */}
              <h2 className="text-[#2B1700] font-semibold mb-4 text-center text-[16px]">
                Transaction Details
              </h2>
              <div className="space-y-3">
  {Object.entries(transactionDetails)
    .flatMap(([key, value]) => {
      if (key === "paymentDetails" && typeof value === "object" && value !== null) {
        // Extract only allowed keys from paymentDetails
        return Object.entries(value)
          .filter(([subKey]) => customLabels[subKey])
          .map(([subKey, subValue]) => [subKey, subValue]);
      }
      return customLabels[key] ? [[key, value]] : []; // Include only customLabels keys
    })
    .map(([key, value], index) => (
      <div key={index} className="grid grid-cols-3 gap-4">
        <span className="text-[#6C5D2F] font-semibold col-span-1">
          {customLabels[key]}
        </span>
        <span className="text-[#2B1700] font-medium col-span-2">
          {value?.toString() || "N/A"}
        </span>
      </div>
    ))}
</div>

            </div>

            {/* Action Buttons */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* <button className="bg-[#FF5722] hover:bg-[#3a0b01] text-white py-3 px-6 rounded-lg transition-colors duration-200 flex items-center justify-center gap-2">
            {/* <button className="bg-[#FF5722] hover:bg-[#3a0b01] text-white py-3 px-6 rounded-lg transition-colors duration-200 flex items-center justify-center gap-2">
              <FileDownloadIcon />
              Download Receipt
            </button>
            
            <button className="border-2 border-[#8F4C38] text-[#8F4C38] py-3 px-6 rounded-lg hover:bg-[#F5DED8] transition-colors duration-200 flex items-center justify-center gap-2">
              <ShareIcon />
              Share Receipt
            </button> */}
            </div>
          </div>

          {/* Return Button */}
          <button
            onClick={gotohomepage}
            className="w-full bg-white border border-[#F1E3D6] text-[#333333] py-4 px-6 rounded-lg hover:bg-[#F1E3D6] transition-colors duration-200 flex items-center justify-center gap-2"
          >
            <ArrowBackIcon />
            Redirecting to Dashboard in {countdown}s...
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
