const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ALLOFFERINGS =
  REACT_APP_API_BASE_URL + "/public/api/v1/offering/all?pageNumber=";
const PARTICULARCHADAWA =
  REACT_APP_API_BASE_URL + "/public/api/v1/offering/name/";

const PARTICULARCHADAWABYID =
  REACT_APP_API_BASE_URL + "/public/api/v1/offering/";
const HOMEPAGEBANNER =
  REACT_APP_API_BASE_URL + "/public/api/v1/homepage/banner/all";
const ALLPUJA = REACT_APP_API_BASE_URL + "/public/api/v1/puja/all";
const PARTICULARPUJA = REACT_APP_API_BASE_URL + "/public/api/v1/puja/name/";
const PARTICULARPUJABYID = REACT_APP_API_BASE_URL + "/public/api/v1/puja/";

// FAQ Endpoints

// Homepage

const HOMEPAGEFAQ = REACT_APP_API_BASE_URL + "/public/api/v1/faq/homepage";

// offering

const OFFERINGFAQ = REACT_APP_API_BASE_URL + "/public/api/v1/faq/offering";

// puja

const PUJAFAQ = REACT_APP_API_BASE_URL + "/public/api/v1/faq/puja";

//testimonial

const TESTIMONIAL = REACT_APP_API_BASE_URL + "/public/api/v1/review/all";

//cart
const CART = REACT_APP_API_BASE_URL + "/public/api/v1/cart/";

//create order
const CREATEORDER = REACT_APP_API_BASE_URL + "/public/api/v1/order/create";

// payment

const PAYMENTINITIATE =
  REACT_APP_API_BASE_URL + "/public/api/v1/payment/initiate";

const TRANSACTIONDETAILS =
  REACT_APP_API_BASE_URL + "/public/api/v1/order/get?orderId=";
// otp
const OTPSEND = REACT_APP_API_BASE_URL + "/public/api/v1/client/auth/otp/send";
const OTPVERIFY =
  REACT_APP_API_BASE_URL + "/public/api/v1/client/auth/otp/verify";
const GETOTP = REACT_APP_API_BASE_URL + "/private/api/v1/user/otp/get";
const PUTUSER = REACT_APP_API_BASE_URL + "/public/api/v1/user/update";

//profile
const GETUSERDATA = REACT_APP_API_BASE_URL + "/public/api/v1/user/get";

// orders

const GETALLORDERS = REACT_APP_API_BASE_URL + "/public/api/v1/order/getAll";
const GTEORDERDETILSBYID = REACT_APP_API_BASE_URL + "/public/api/v1/order/";

export {
  ALLOFFERINGS,
  PARTICULARCHADAWA,
  HOMEPAGEBANNER,
  ALLPUJA,
  PARTICULARPUJA,
  HOMEPAGEFAQ,
  OFFERINGFAQ,
  PUJAFAQ,
  TESTIMONIAL,
  CART,
  CREATEORDER,
  PAYMENTINITIATE,
  TRANSACTIONDETAILS,
  OTPSEND,
  OTPVERIFY,
  PARTICULARPUJABYID,
  GETOTP,
  PUTUSER,
  PARTICULARCHADAWABYID,
  GETUSERDATA,
  GETALLORDERS,
  GTEORDERDETILSBYID,
};
