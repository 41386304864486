import React from "react";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const DateSection = ({ offering }) => {
  const dateStr = offering?.eventDateTime;
  const date = new Date(dateStr);

  const formattedDate = date.toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const formattedTime = date.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return (
    <div className="flex flex-wrap text-sm">
      <div className="flex items-center justify-center space-x-1 bg-light-pink rounded-3xl max-h-7 px-3 py-2 mr-4">
        <span
          role="img"
          aria-label="calendar"
          className="flex items-center justify-center text-custom-maroon"
        >
          <CalendarTodayIcon sx={{ fontSize: 16 }} />
        </span>
        <span className="font-poppins font-semibold text-custom-maroon">
          {formattedDate}
        </span>
      </div>
      <div className="flex items-center justify-center space-x-1 bg-light-pink rounded-3xl max-h-7 px-3 py-2 sm:mt-0">
        <span
          role="img"
          aria-label="clock"
          className="flex items-center justify-center text-custom-maroon"
        >
          <AccessTimeIcon sx={{ fontSize: 16 }} />
        </span>
        <span className="font-poppins font-semibold text-custom-maroon">
          {formattedTime}
        </span>
      </div>
    </div>
  );
};

export default DateSection;
