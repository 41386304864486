
import React from "react";
import { useVisibility } from "./VisibilityContext";

const ConditionalRenderer = ({ componentKey, children }) => {
  const { visibility, loading } = useVisibility();

  if (loading) {
    return null; // Optionally, return a loading spinner
  }

  return visibility?.[componentKey] ? children : null;
};

export default ConditionalRenderer;
