import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const OfferSection = ({ offering }) => {
  const itemImages = offering.offeringItemImageList;

  const [positions, setPositions] = useState(
    itemImages.map((_, index) => index) // Initial positions
  );

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setPositions((prev) => {
        const [first, ...rest] = prev;
        return [...rest, first]; // Rotate the positions array
      });
    }, 5000); // Change position every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const manageOffer = () => {
    localStorage.setItem("particularChadawa", JSON.stringify(offering));

    const mainSection = offering.mainSection;
    const id = offering.id;
    navigate(`/chadawa/${mainSection.name}`, { state: { id } });
  };
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleImageClick = (index) => {
    setCurrentIndex(index); // Set the clicked image as the center
  };

  // eventpassed start#########################
  const eventPassed = new Date() > new Date(offering.mainSection.eventDateTime);
  // eventpassed end#########################

  return (
    <div className="flex flex-wrap justify-between items-center mt-4">
      <div className=" flex mb-4 sm:mb-0 h-14">
        {itemImages.map((image, index) => (
          <div
            key={index}
            className={`
          absolute
          ml-2
          transition-all
          hover:scale-105
          hover:translate-y-[-4px]
          ${positions[index] === 0 ? "left-2" : ""}
          ${positions[index] === 1 ? "left-6" : ""}
          ${positions[index] === 2 ? "left-10" : ""}
          ${positions[index] === 3 ? "left-14" : ""}
        `}
          >
            <img
              src={image.url}
              alt={image.alt}
              className="w-14 h-14 rounded-xl object-cover shadow-sm hover:shadow-md transition-shadow"
            />
          </div>
        ))}
      </div>

      <button
        onClick={manageOffer}
        disabled={eventPassed} // Disable the button if eventPassed is true
        className={`h-12 sm:w-[12vw] ${
          eventPassed
            ? "bg-gray-400 cursor-not-allowed"
            : "bg-custom-orange hover:bg-orange-600"
        } text-white px-4 sm:px-6 py-2 rounded-[50px] font-poppins sm:text-[1vw] font-semibold`}
      >
        {eventPassed ? "Event Ended" : "OFFER CHADAWA"}{" "}
        {/* Display text dynamically */}
      </button>
    </div>
  );
};

export default OfferSection;
