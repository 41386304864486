import React, { useRef, useEffect } from "react";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material"; // Import Material UI icons
import PujaEventCard from "./pujaeventcard";
import ExploreMoreCard from "../../devsathan_components/ExploreMore/exploremore";
import { useNavigate } from "react-router-dom";

const PujaEventSlider = ({ events }) => {
  const sliderRef = useRef(null);
  const navigate = useNavigate();

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -300,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 300,
        behavior: "smooth",
      });
    }
  };
  const handleExploreMore = () => {
    // Navigate to the new page with explore more
    navigate("/puja-listing");
  };

  // useEffect(() => {
  //   if (sliderRef.current && events.length > 0) {
  //     sliderRef.current.scrollLeft = 0;
  //   }
  // }, [events]);

  return (
    <div className="w-full overflow-hidden pt-8 relative">
      <button
        onClick={scrollLeft}
        className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 p-2 bg-white rounded-full hidden sm:block"
      >
        <ArrowBackIos className="px-1" />
      </button>

      <div className=" mx-auto px-0 md:px-20">
        <div
          ref={sliderRef}
          className="flex gap-7 sm:gap-40 overflow-x-auto pb-4 snap-x w-full [&::-webkit-scrollbar]:w-0 [&::-webkit-scrollbar]:h-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-neutral-700
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 scroll-smooth"
        >
          {events.map((pujaEvent) => (
            <div
              key={pujaEvent.id}
              className="snap-start flex-shrink-0 w-[84.5vw] sm:w-72"
            >
              <PujaEventCard
                pujaEvent={pujaEvent}
                onButtonClick={() =>
                  navigate(
                    `/puja/${pujaEvent?.mainSection?.name.replace(/\s+/g, "_")}`
                  )
                }
              />
            </div>
          ))}

          {events.length > 0 && (
            <div className="snap-start flex-shrink-0 w-72 h-full p-2 pt-16 pb-9">
              <ExploreMoreCard onClick={handleExploreMore} />
            </div>
          )}
        </div>
      </div>

      <button
        onClick={scrollRight}
        className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 p-2 bg-white rounded-full hidden sm:block"
      >
        <ArrowForwardIos className="px-1" />
      </button>
    </div>
  );
};

export default PujaEventSlider;
