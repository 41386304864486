import TestimonialCard from "../Cards/TestimonialCard";

const Testimonials = ({ testimonials }) => {
  return (
    <div className="pb-10">
      <div className="w-full mx-auto text-center sm:mt-24 mt-12 rounded-[24px]">
        <div>
          <h2 className="text-wrap font-bold font-poppins text-3xl md:text-4xl lg:text-4xl text-custom-maroon lg:px-7 mb-4 whitespace-nowrap">
            What Our Devotees Say
          </h2>
          <p className="text-wrap text-md md:text-lg lg:text-base text-custom-brown mt-2 font-poppins font-normal px-2">
            Discover heartfelt testimonials from devotees about their divine
            experiences at Devsthan
          </p>
        </div>
        <TestimonialCard testimonials={testimonials} />
      </div>
    </div>
  );
};

export default Testimonials;
