import React from "react";
import { useNavigate } from "react-router-dom";

const ImageWithText = ({ goToPage, slides }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    console.log("Slide", slides);

    navigate(slides?.imageInfo?.onClick); // Navigate within the app
  };

  return (
    <div onClick={handleClick}>
      {/* Image */}
      <img
        src={slides?.imageInfo?.url}
        alt={slides?.imageInfo?.altText}
        className="w-full h-full object-cover"
      />

      {/* Overlay Text */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent">
        <div className="absolute bottom-0 left-0 p-4 custom-md:p-6 text-white">
          <h2 className="text-lg custom-md:text-2xl font-bold mb-1 custom-md:mb-2">
            {slides.title}
          </h2>
          <p className="text-xs custom-md:text-sm">{slides.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ImageWithText;
