import React from "react";
import { useState } from "react";

import OTPVerification from "../components/Auth/Otp";
import SignUp from "../components/Auth/SignUp";

const Auth = () => {
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-light-pink px-10">
      {isOtpVerified ? (
        <SignUp />
      ) : (
        <OTPVerification
          setUserDetails={setUserDetails}
          setIsOtpVerified={setIsOtpVerified}
        />
      )}
    </div>
  );
};

export default Auth;
