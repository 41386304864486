import { loadToken } from "./localStorage";

export const getAuthHeaders = () => {
  // Load the token using the loadToken function
  const token = loadToken();

  // Prepare headers with Content-Type by default
  const headers = {
    "Content-Type": "application/json",
  };

  // Only add the x-access-token header if the token is valid
  if (token) {
    headers["x-access-token"] = token;
  } else {
    console.log('No x-access-token header added.');
  }
  return headers;
};