// utils/localStorage.js
export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("cartState", serializedState);
  } catch (error) {
    console.error("Could not save state", error);
  }
};

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("cartState");
    if (serializedState === null) return undefined; // Return undefined if no saved state
    return JSON.parse(serializedState);
  } catch (error) {
    console.error("Could not load state", error);
    return undefined;
  }
};

// set user sugnup token to pass as user token
export const saveToken = (token) => {
  console.log("token entered in saving", token);
  try {
    console.log("token entered in saving", token);
    localStorage.setItem("userToken", token);

  } catch (error) {
    console.error("Could not save token", error);
  }
};

export const loadToken = () => {
  try {
    const token = localStorage.getItem('userToken');// Should be 'string'
    if (token && token.trim() !== '') {
      return token;
    } else {
      // Token is either null, undefined, or an empty string
      console.warn('No valid token found.');
      return null;
    }
  } catch (error) {
    // If there was an error accessing localStorage
    console.error('Could not load token:', error);
    return null;
  }
};

export const generalsaveToken = (key, token) => {
  try {
    localStorage.setItem(key , token);
  }
  catch (error) {
    console.error("Could not save token", error);
  }
}

export const generalloadToken = (key) => {
  try {
    const token = localStorage.getItem(key);
    if (token && token.trim() !== '') {
      return token;
    } else {
      console.warn('No valid token found.');
      return null;
    }
  } catch (error) {
    console.error('Could not load token:', error);
    return null;
  }
};

export  const removegeneralToken = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error("Could not remove token", error);
  }
}

