// contexts/VisibilityContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

const VisibilityContext = createContext();

export const VisibilityProvider = ({ children }) => {
  const [visibility, setVisibility] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch visibility configuration from the API
  useEffect(() => {
    const fetchVisibilityConfig = async () => {
      try {
        // const response = await axios.get("https://api.example.com/visibility-config");
        const response = {};
        response.data = {
          header: true,
          footer: false,
          sidebar: true,
          SubscribeLandingpage: false,
          whatsappbutton: false,
          filter: false,
          navbarsearchicon: false,
        };
        setVisibility(response.data); // Assuming API returns { header: true, footer: false, sidebar: true }
      } catch (error) {
        console.error("Error fetching visibility config:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVisibilityConfig();
  }, []);

  return (
    <VisibilityContext.Provider value={{ visibility, loading }}>
      {children}
    </VisibilityContext.Provider>
  );
};

export const useVisibility = () => useContext(VisibilityContext);
