import React from "react";
import { useState } from "react";

import ImageSection from "./offeringCardComponents/ImageSection";
import DateSection from "./offeringCardComponents/DateSection";
import OfferSection from "./offeringCardComponents/OfferSection";
import TitleAndLocation from "./offeringCardComponents/TitleAndLocation";

const OfferingCard = ({ offerings }) => {
  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mx-4 sm:mx-6 lg:mx-16 px-2 min-w-[20vw] mt-8">
        {offerings.map((offering) => (
          <div
            key={offering.id}
            className="w-full min-w-[25vw] mx-auto border bg-[#fdf4e8] rounded-[24px] overflow-hidden relative flex flex-col shadow-xl"
          >
            <ImageSection offering={offering} />

            <div className="flex flex-col flex-grow px-4 pb-1 pt-2 bg-[#fdf4e8]">
              <div className="flex-grow">
                <DateSection offering={offering.mainSection} />
                <TitleAndLocation offering={offering.mainSection} />
              </div>
              <OfferSection offering={offering} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OfferingCard;
