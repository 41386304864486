const DiscountIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-discount"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <line x1="9" y1="15" x2="15" y2="9" />
    <circle cx="9.5" cy="9.5" r=".5" fill="currentColor" />
    <circle cx="14.5" cy="14.5" r=".5" fill="currentColor" />
    <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7a2.2 2.2 0 0 0 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1a2.2 2.2 0 0 0 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1" />
  </svg>
);

const DiscountLabel = ({ discount }) => (
  <div
    className="absolute bottom-0 bg-[#ba1a1a] text-white text-xs font-semibold px-3 py-1 rounded-br-[24px] rounded-bl-[24px] w-full"
    style={{ height: "50px" }}
  >
    <div className="flex items-center justify-center h-full">
      <DiscountIcon />
      <span className="ml-2 font-poppins text-[12px] md:text-[1.3vw] lg:text-[1vw] font-semibold">
        {discount}
      </span>
    </div>
  </div>
);

export default DiscountLabel;
