import React from "react";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PaymentIcon from '@mui/icons-material/Payment';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import FavoriteIcon from '@mui/icons-material/Favorite';

const InformationSection2 = () => {
  const information = [
    {
      id: 1,
      icon: <AccessTimeIcon />,
      title: "Select Puja Seva",
      description: "Choose the package for Puja.",
    },
{
      id: 2,
      icon: <AssignmentIcon/>,
      title: "Provide Sankalp Details",
      description: "Enter your Name and Gotra for blessings.",
    },
    {
      id: 3,
      icon: <LocalShippingIcon/>,
      title: "Add Prasad Home Delivery (Optional)",
      description: "Get Prasad delivered to your doorstep.",
    },
    {
      id: 4,
      icon: <PaymentIcon />,
      title: "Make Payment",
      description: "Securely complete your booking online.",
    },
    {
      id: 5,
      icon: <EventAvailableIcon />,
      title: "Puja Seva Conducted as per Muhurat",
      description: "Puja performed at the most auspicious time.",
    },
    {
      id: 6,
      icon: <VideoLibraryIcon />,
      title: "Puja Video & Ashirwad Box",
      description: "Receive a personalized video and blessings.",
    },
    {
      id: 7,
      icon: <FavoriteIcon />,
      title: "Feel the Divine Blessings",
      description: "Experience peace and positivity.",
    },
  ];
  return (
    <div className="lg:pl-44">
      <div className="grid lg:grid-cols-1 grid-cols-1 sm:grid-cols-2 gap-2">
        {information.map((info) => (
          <div key={info.id} className="flex flex-col md:flex-col">
            <div className="flex flex-row">
              <div className="text-custom-orange pr-2">{info.icon}</div>
              <h1 className="font-poppins font-semibold text-[1.1rem] text-custom-maroon pb-1">
                {info.title}
              </h1>
            </div>
            <div>
              <p className="font-poppins font-light text-sm text-custom-light-brown pb-2">
                {info.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InformationSection2;
