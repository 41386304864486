import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef, useCallback } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Chip,
  Button,
  Paper,
  IconButton,
  CircularProgress,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ShoppingBag as ShoppingBagIcon } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import { GETALLORDERS } from "../rest/endpoints";
import { getAuthHeaders } from "../utils/getHeaders";
import RazorpayPayment from "../components/Common/RazorpayPayment";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import AuthModal from "../components/Auth/AuthModal";
import { useLocation } from "react-router";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CancelIcon from "@mui/icons-material/Cancel";
import PaymentIcon from "@mui/icons-material/Payment";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { apiRequest } from "../utils/apiRequest";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import PaymentOutlined from "@mui/icons-material/PaymentOutlined";
import CalendarTodayOutlined from "@mui/icons-material/CalendarTodayOutlined";
import InfoOutlined from "@mui/icons-material/InfoOutlined";



const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFE0B2", // Soft peach background
    color: "#8F4C38", // Dark brown text
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)", // Soft shadow
    fontSize: theme.typography.pxToRem(12),
    borderRadius: "8px",
    padding: "8px 12px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFE0B2", // Match arrow to background
  },
}));

const getStatusData = (status) => {
  const lowerStatus = status;

  switch (lowerStatus) {
    case "CREATED":
      return {
        color: "success",
        icon: <HourglassEmptyIcon />, // Waiting icon
      };

    case "AWAITING_PAYMENT_CONFIRMATION":
      return {
        color: "warning",
        icon: <PaymentIcon />, // Payment icon
      };

    case "PAYMENT_FAILED":
      return {
        color: "error",
        icon: <CancelIcon />, // Cross (failure) icon
      };

    case "SUCCESS":
      return {
        color: "success",
        icon: <CheckCircleIcon />, // Success checkmark
      };

    case "completed":
      return {
        color: "success",
        icon: <DoneAllIcon />, // Double checkmark (completed)
      };

    case "in transit":
      return {
        color: "info",
        icon: <LocalShippingIcon />, // Shipping truck icon
      };

    default:
      return {
        color: "default",
        icon: <AutorenewIcon />, // Spinning refresh icon (unknown status)
      };
  }
};

const OrdersPage = () => {
  const navigate = useNavigate();
  const headers = getAuthHeaders();
  const [allOrders, setAllOrders] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [hoveredOrder, setHoveredOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();

  const [openTooltipId, setOpenTooltipId] = useState(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const scrollRef = useRef(null);

  const handleTooltipOpen = (orderId) => {
    setOpenTooltipId((prev) => (prev === orderId ? null : orderId)); // Toggle only the clicked tooltip
  };

  const handleTooltipClose = () => {
    setOpenTooltipId(null);
  };
  
  // Function to fetch orders
  const fetchOrders = async (page) => {
    if (!hasMore || loading) return;
    
    setLoading(true);
    try {
      const response = await apiRequest({
        url: GETALLORDERS,
        method: "POST",
        data: {
          pageNumber: page,
        },
        headers,
      });
      console.log("Response", response);

      
        const newOrders = response.data.content;
        console.log("Order Data", response.data.content);
        // Update total pages
        setTotalPages(response.data.totalPages );
        
        // Append new orders to existing ones
        setAllOrders((prevOrders) => [...prevOrders, ...newOrders]);
        
        // Check if we've reached the last page
        setHasMore(page < response.data.totalPages - 1);
        console.log(`Loaded page ${page}/${response.data.totalPages - 1}`);
    } catch (error) {
      console.error(error.message || "Something went wrong");
    }
    finally {
      setLoading(false);
    }
  };

  // Initial load
  useEffect(() => {
    fetchOrders(0);
  }, []);

  // Intersection Observer for infinite scrolling
  const observer = useRef();
  const lastOrderElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPage) => {
            const nextPage = prevPage + 1;
            fetchOrders(nextPage);
            return nextPage;
          });
        }
      });
      
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
<Box
  sx={{
    minHeight: "100vh",
    background: "linear-gradient(135deg, #FFF7F0 0%, #FFEDE5 50%, #FFD4C5 100%)",
    py: 2,
    px: 2,
  
  }}
>
<Paper
  sx={{
    maxWidth: { 
      xs: "95%",    // Mobile - nearly full width with small margin
      sm: 500,      // Tablet - fixed width of 500px
      md: 650,      // Small desktop - wider fixed width
      lg: 800       // Large desktop - maximum width of 800px
    },
    mx: "auto",
    mt: { xs: 8, md: 8 },  // Less top margin on mobile
    mb: 2,
    borderRadius: 3,
    background: "rgba(255, 255, 255, 0.95)",
    backdropFilter: "blur(15px)",
    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.08)",
    overflow: "hidden",
  }}
>
    <Box sx={{ 
      display: "flex", 
      alignItems: "center", 
      justifyContent: "center",
      gap: 1.5, 
      py: 2,
      borderBottom: "1px solid rgba(0,0,0,0.06)"
    }}>
      <ShoppingBagIcon 
        sx={{ 
          fontSize: 28, 
          color: "#FF5722",
          
        }} 
      />
      <Typography
        variant="h5"
        sx={{
          fontWeight: 800,
          background: "linear-gradient(135deg, #FF5722 0%, #F4511E 100%)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          color: "transparent",
          fontSize: "22px",
          letterSpacing: "-0.3px",
        }}
      >
        Your Bookings
      </Typography>
    </Box>

    {/* Individual Booking Card */}
    <Box sx={{ px: 2, py: 2 }}>
  {allOrders.map((order, index) => {
    const isLastElement = index === allOrders.length - 1;
    const { color, icon } = getStatusData(order.orderStatus);
    
    return (
      <Card
        key={order.orderId}
        ref={isLastElement ? lastOrderElementRef : null}
        sx={{
          mb: 2.5,
          overflow: "hidden",
          borderRadius: 2.5,
          background: "#FDF4E8",
          border: "1px solid rgba(0,0,0,0.05)",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.04)",
        }}
      >
        {/* Header with ID and Price */}
        <Box
          sx={{
            background: "linear-gradient(135deg, #FF5722 0%, #F4511E 100%)",
            py: 0.3,
            px: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ maxWidth: "70%" }}>
            <Typography
              variant="caption"
              sx={{
                color: "rgba(255,255,255,0.7)",
                fontSize: "11px",
                display: "block",
                mb: 0.2,
                textAlign: "left",
              }}
            >
              Booking ID
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "white",
                fontWeight: 600,
                fontSize: "13px",
                wordBreak: "break-all",
              }}
            >
              {order.orderId}
            </Typography>
          </Box>
          
          {/* Price moved to header where status was */}
          <Typography
            variant="h6"
            sx={{
              fontWeight: 700,
              color: "white",
              fontSize: "20px",
            }}
          >
            ₹{order.totalAmount?.toFixed(2) || "59.99"}
          </Typography>
        </Box>

        {/* Booking Content */}
        <Box sx={{ p: 2 }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 700,
              color: "#8F4C38",
              fontSize: "16px",
              mb: 1,
              lineHeight: 1.3,
            }}
          >
            {order.eventName || "Makar Sankranti - Surya Ganga Sankalp"}
          </Typography>
          
          {/* Date and Status in one row with extreme right alignment */}
          <Box sx={{ 
            display: "flex", 
            alignItems: "center", 
            justifyContent: "space-between",
            mb: 2
          }}>
            {/* Date Section */}
            <Box sx={{ 
              display: "flex", 
              alignItems: "center", 
              gap: 1
            }}>
              <CalendarTodayOutlined sx={{ fontSize: 14, color: "text.secondary" }} />
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", fontSize: "13px" }}
              >
                {order.orderDate 
                  ? new Date(order.orderDate).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric'
                    }) 
                  : "Mar 5, 2025"}
              </Typography>
            </Box>
            
            {/* Tooltip and Status Section - tightly packed and right aligned */}
            <Box sx={{ 
              display: "flex", 
              alignItems: "center",
              ml: "auto" // Pushes to the far right
            }}>
              {/* Tooltip with minimal right margin */}
              <CustomTooltip
                title={order.orderDescription || "Booking details and information"}
                arrow
                open={openTooltipId === order.orderId}
                disableHoverListener
                disableFocusListener
                placement="top"
              >
                <IconButton
                  onClick={() => handleTooltipOpen(order.orderId)}
                  size="small"
                  sx={{ 
                    color: "#8F4C38",
                    p: 0.5, // Reduced padding
                    mr: 0.5 // Minimal right margin
                  }}
                >
                  <InfoIcon fontSize="small" />
                </IconButton>
              </CustomTooltip>
              
              {/* Status Chip */}
              <Chip
                icon={icon}
                label={order.orderDisplayName || "CREATED"}
                color={color}
                size="small"
                sx={{ 
                  fontSize: "11px", 
                  fontWeight: 600,
                  height: 24,
                  "& .MuiChip-icon": {
                    fontSize: 16
                  }
                }}
              />
            </Box>
          </Box>
          
          {/* Action Buttons */}
          <Box sx={{ 
            display: "flex", 
            gap: 2,
            mt: 1
          }}>
            {order?.paymentDetails?.showPaymentButton && (
              <Button
                variant="contained"
                fullWidth
                sx={{
                  background: "linear-gradient(135deg, #FF5722 0%, #F4511E 100%)",
                  color: "white",
                  fontWeight: 600,
                  fontSize: "13px",
                  borderRadius: 6,
                  boxShadow: "0 4px 10px rgba(244, 81, 30, 0.2)",
                  "&:hover": { 
                    background: "linear-gradient(135deg, #F4511E 0%, #E64A19 100%)",
                  }
                }}
                startIcon={<PaymentOutlined sx={{ fontSize: 18 }} />}
                onClick={() => setSelectedOrderId(order.orderId)}
              >
                PAY NOW
              </Button>
            )}
            
            <Button
              variant="outlined"
              fullWidth
              sx={{
                borderColor: "#FF5722",
                color: "#FF5722",
                fontWeight: 600,
                fontSize: "13px",
                py: 1,
                borderRadius: 6,
                "&:hover": { 
                  borderColor: "#F4511E",
                  background: "rgba(255, 87, 34, 0.05)"
                }
              }}
              startIcon={<VisibilityOutlined sx={{ fontSize: 18 }} />}
              onClick={() => navigate(`/orders/${order.orderId}`)}
            >
              DETAILS
            </Button>
          </Box>
          
          {/* Render RazorpayPayment component when selectedOrderId matches */}
          {selectedOrderId === order.orderId && (
            <Box sx={{ mt: 2 }}>
              <RazorpayPayment orderId={order.orderId} />
            </Box>
          )}
        </Box>
      </Card>
    );
  })}

  {/* Loading and End of List */}
  <Box sx={{ textAlign: "center", py: 2 }}>
    {loading && (
      <CircularProgress 
        size={24} 
        thickness={4} 
        sx={{ color: "#8F4C38" }} 
      />
    )}

    {!hasMore && allOrders?.length > 0 && (
      <Typography
        variant="body2"
        sx={{ 
          color: "#8F4C38", 
          fontSize: "12px",
          opacity: 0.7
        }}
      >
        No more bookings to load
      </Typography>
    )}
  </Box>
</Box>
  </Paper>

  <AuthModal
    isOpen={showModal}
    onClose={() => setShowModal(false)}
    message="Please Login"
  />
</Box>
  );
};

export default OrdersPage;
