import React from "react";
import { Link } from "react-router-dom";

import Filters from "./filters";
import ConditionalRenderer from "../../devsathan_components/ConditionalRenderer";

function ChadawaHeader() {
  return (
    <>
      <div className="">
        <div className="text-sm pt-2 pb-4 lg:px-6 text-gray-600 mt-16  font-bold">
          {/* <Link to="/" className="pl-1 text-custom-brown">
            <span>Home</span>
          </Link>
          <span className="mx-2 font-poppins font-semibold">/</span>
          <span className="font-poppins font-semibold text-custom-orange">
            Book Puja
          </span> */}
        </div>

        <h1 className="text-3xl md:text-4xl lg:text-4xl text-custom-maroon -mb-2 sm:mb-4 font-poppins font-bold whitespace-nowrap">
          Upcoming Pujas
        </h1>
        <div class="sticky mt-100 mb-2  sm:mb-24 bg-[#ffdad6]">
          <ConditionalRenderer componentKey="filter">
            <Filters />
          </ConditionalRenderer>
        </div>
      </div>
    </>
  );
}

export default ChadawaHeader;
