export const withRetry = async ({
  operation,
  retries = 3,
  delay = 1000,
  onRetry = null,
}) => {
  let attempt = 0;
  const retryDelays = [1000, 2000, 4000]; // Retry delays: 1s, 2s, 4s

  while (attempt < retries) {
    try {
      const response = await operation();

      // If response contains showModal (401/403 case), return immediately
      if (response?.showModal) {
        return response;
      }

      return response; // Successful response, return result
    } catch (error) {
      attempt++;

      // Extract status code from the error message (if possible)
      const statusCodeMatch = error.message.match(/\d+/);
      const statusCode = statusCodeMatch
        ? parseInt(statusCodeMatch[0], 10)
        : null;

      // Stop retrying for 2xx, 401, or 403 responses
      if (
        statusCode &&
        ((statusCode >= 200 && statusCode < 300) ||
          statusCode === 401 ||
          statusCode === 403)
      ) {
        throw error;
      }

      if (attempt >= retries) {
        throw error; // Max retries reached, throw the error
      }

      if (onRetry) {
        onRetry(attempt, error); // Callback for retry logging or notifications
      }

      await new Promise((resolve) =>
        setTimeout(resolve, retryDelays[attempt - 1])
      ); // Wait before retrying
    }
  }
};
