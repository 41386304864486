import React from "react";
import { useState, useEffect } from "react";
import PujaEventDisplay from "../components/puja_slider/PujaEventDisplay";
import ChadawaHeader from "../components/PujaListing/header";
import FAQSection from "../components/Common/FAQ";
import Testimonials from "../components/Common/Testimonials";
import SubscribeLandingpage from "../components/subscribe_landingpage";
import { ALLPUJA, PUJAFAQ, TESTIMONIAL } from "../rest/endpoints";
import ConditionalRenderer from "../devsathan_components/ConditionalRenderer";
import { getAuthHeaders } from "../utils/getHeaders";
import { apiRequest } from "../utils/apiRequest";
import { toast } from "react-toastify";

const ChadawaOfferings = () => {
  const headers = getAuthHeaders();
  const [offerings, setOfferings] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [pujaEvents, setPujaEvents] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPooja = async () => {
      try {
        const result = await apiRequest({
          url: ALLPUJA,
          method: "GET",
          headers,
        });

        if (result?.data?.pujaList && Array.isArray(result.data.pujaList)) {
          setPujaEvents(result.data.pujaList);
        } else {
          console.error("Offerings data is not available or not an array");
          toast.error("Failed to load puja events. Please try again.");
        }
      } catch (error) {
        console.error(
          "Error fetching puja events:",
          error.message || "Something went wrong"
        );
        toast.error("Error fetching puja events. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchPooja();
  }, []);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const result = await apiRequest({
          url: TESTIMONIAL,
          method: "GET",
          headers,
        });

        // Check if testimonial data is an array and update state
        if (result?.data?.reviews && Array.isArray(result.data.reviews)) {
          setTestimonials(result.data.reviews);
        } else {
          console.error("Testimonial data is not available or not an array");
          toast.error("Testimonial data is not available.");
        }
      } catch (error) {
        // Handle errors
        console.error(
          "Error fetching testimonials:",
          error.message || "Something went wrong"
        );
        toast.error("Error fetching testimonials. Please try again.");
      }
    };

    fetchTestimonials();
  }, []);

  useEffect(() => {
    const fetchfaq = async () => {
      try {
        const result = await apiRequest({
          url: PUJAFAQ,
          method: "GET",
          headers,
        });

        // Check if FAQ data is an array and update state
        if (result?.data?.faqs && Array.isArray(result.data.faqs)) {
          setFaqs(result.data.faqs);
        } else {
          console.error("FAQ data is not available or not an array");
          toast.error("FAQ data is not available.");
        }
      } catch (error) {
        // Handle errors
        console.error(
          "Error fetching FAQs:",
          error.message || "Something went wrong"
        );
        toast.error("Error fetching FAQs. Please try again.");
      }
    };

    fetchfaq();
  }, []);

  return (
    <div className="bg-[#FFECE5] min-h-screen  pt-1 -mt-10 md:-mt-16">
      <div className=" max-w-full mx-auto px-6 sm:px-16  py-10 md:py-20">
        <div>
          <ChadawaHeader />
        </div>
        {/* <OfferingCard offerings={offerings} /> */}
        {isLoading ? (
          <div className="flex justify-center items-center h-screen">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#FFB830]"></div>
            <p className="text-center text-lg font-semibold text-custom-maroon">
              Loading...
            </p>
            <div className="animate-ping rounded-full h-32 w-32 border-t-2 border-b-2 border-[#FFB830]"></div>
          </div>
        ) : (
          <div className="overflow-x-auto whitespace-nowrap  sm:-mt-20">
            <PujaEventDisplay events={pujaEvents} viewType="list" />
          </div>
        )}
        <div>
          <ConditionalRenderer componentKey="SubscribeLandingpage">
            <SubscribeLandingpage />
          </ConditionalRenderer>
        </div>
        <div className="md:px-14 -mb-10 sm:-mt-10  ">
          <Testimonials testimonials={testimonials} />
        </div>

        <div className="mt-8 -mx-4 sm:mt-2 sm:-mb-10">
          <FAQSection faqs={faqs} />
        </div>
      </div>
    </div>
  );
};

export default ChadawaOfferings;
