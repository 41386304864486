import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./redux/store/store";
import App from "./App";
import "./index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TagManager from "react-gtm-module";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);
console.log("REACT_APP_API_GTM_ID", process.env.REACT_APP_API_GTM_ID);
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_API_GTM_ID, 
};

TagManager.initialize(tagManagerArgs); 
root.render(
  <Provider store={store}>
    <App />
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  </Provider>
);
