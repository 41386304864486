import React, { useState, useEffect } from "react";

const TimeLeft = ({ bookingClosingTime }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    // const targetDate = new Date(bookingClosingTime);
    const targetDate = new Date(bookingClosingTime);

    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference <= 0) {
        // Stop countdown when target time is reached
        return {
          days: "00",
          hours: "00",
          minutes: "00",
          seconds: "00",
        };
      }

      // Calculate time components
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      return {
        days: String(days).padStart(2, "0"),
        hours: String(hours).padStart(2, "0"),
        minutes: String(minutes).padStart(2, "0"),
        seconds: String(seconds).padStart(2, "0"),
      };
    };

    // Update timeLeft every second
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [bookingClosingTime]); // Recalculate whenever bookingClosingTime changes

  return (
    <div className="flex items-baseline space-x-2">
      {timeLeft.days !== 0 && (
        <div className="flex flex-col items-center pl-1">
          <span className="text-black font-bold text-xl font-poppins">
            {timeLeft.days}
          </span>
          <span className="text-gray-500 text-xs font-poppins">days</span>
        </div>
      )}

      <div className="flex flex-col items-center pl-1">
        <span className="text-black font-bold text-xl font-poppins">
          {timeLeft.hours}
        </span>
        <span className="text-gray-500 text-xs font-poppins">hrs</span>
      </div>

      <div className="flex flex-col items-center pl-1">
        <span className="text-black font-bold text-xl font-poppins">
          {timeLeft.minutes}
        </span>
        <span className="text-gray-500 text-xs font-poppins">mins</span>
      </div>

      <div className="flex flex-col items-center pl-1">
        <span className="text-black font-bold text-xl font-poppins w-[24px] text-center">
          {timeLeft.seconds}
        </span>
        <span className="text-gray-500 text-xs font-poppins">sec</span>
      </div>
    </div>
  );
};

export default TimeLeft;
