import React, { useState } from "react";

const Subscribe_Landingpage = () => {
  const [email, setEmail] = useState("");
  console.log("email");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle subscription logic here
    console.log("Subscribing email:", email);
  };
  return (
    <>
      <div className="bg-[#fff7f2] py-16 px-4 mt-16 ">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className="text-4xl md:text-6xl font-bold text-[#333333] mb-3">
            Stay Connected with Devsthan
          </h2>

          <p className="text-[#3A0B01] mb-8">
            Join thousands of devotees who trust us for their spiritual journey.
          </p>

          <form
            onSubmit={handleSubmit}
            className="flex flex-col md:flex-row gap-4 justify-center mb-6"
          >
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="px-6 py-3 bg-[#f3e9e3] rounded-full text-[#4e3d3d] placeholder-[#8b7e7e] flex-1 max-w-md outline-none"
              required
            />
            <button
              type="submit"
              className="bg-[#FF5722] text-white px-8 py-3 rounded-full font-medium hover:bg-[#FF5722] transition-colors"
            >
              Subscribe
            </button>
          </form>

          <div className="flex items-center justify-center gap-2 text-[#8F4C38] text-sm">
            <p>
              🌟 No spam, just blessings: We value your privacy and ensure your
              information is 100% secure.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribe_Landingpage;
