import { createContext, useContext, useRef } from "react";
import { useState, useEffect } from "react";

const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
  const waysToParticipateRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);


  const scrollToWaysToParticipate = () => {
    if (waysToParticipateRef.current) {
      waysToParticipateRef.current.scrollIntoView({ behavior: "smooth" });

    }

  };

  useEffect(() => {
    const checkAndObserve = () => {
      if (!waysToParticipateRef.current) {
        console.log("waysToParticipateRef is NULL, retrying...");
        setTimeout(checkAndObserve, 500);
        return;
      }
  
      console.log("Observer Initialized for:", waysToParticipateRef.current);
      
      const observer = new IntersectionObserver(
        ([entry]) => {
          console.log("Observer Triggered - Is Visible:", !entry.isIntersecting);
          setIsVisible(!entry.isIntersecting);
        },
        {
          threshold: 0.1,
          rootMargin: "0px",
        }
      );
  
      observer.observe(waysToParticipateRef.current);
  
      return () => observer.disconnect();
    };
  
    checkAndObserve();
  }, []);
  

  return (
    <ScrollContext.Provider
      value={{ waysToParticipateRef, scrollToWaysToParticipate, isVisible }}
    >
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = () => useContext(ScrollContext);
