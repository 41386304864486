import React from "react";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";

const InformationSection = () => {
  const information = [
    {
      id: 1,
      icon: <AccessTimeIcon />,
      title: "Select Chadawa",
      description:
        "Please choose a puja type from the following four options: Individual, Couple, Family, or Joint Family.",
    },
    {
      id: 2,
      icon: <WorkOutlineOutlinedIcon />,
      title: "Enter your Basic details:",
      description:
        "Please provide your basic information to facilitate the processing of your application.",
    },
    {
      id: 3,
      icon: <DescriptionOutlinedIcon />,
      title: "Make a payment:",
      description:
        "Upon successful payment, you will receive a confirmation receipt.",
    },
    {
      id: 4,
      icon: <ViewInArOutlinedIcon />,
      title: "Enter your Name and Gotra",
      description:
        "To personalize your puja, please provide your Ancestor and Gotra names.",
    },
    {
      id: 5,
      icon: <PersonOutlineOutlinedIcon />,
      title: "Enjoy your personalise Video",
      description: "We appreciate your participation in the Live Puja.",
    },
    {
      id: 6,
      icon: <WorkspacePremiumOutlinedIcon />,
      title: "Prasad Shipped within 1 Week",
      description:
        "Your puja will be delivered within one week of the scheduled date.",
    },
  ];
  return (
    <div className="lg:pl-44">
      <div className="grid lg:grid-cols-1 grid-cols-1 sm:grid-cols-2 gap-2">
        {information.map((info) => (
          <div key={info.id} className="flex flex-col md:flex-col">
            <div className="flex flex-row">
              <div className="text-custom-orange pr-2">{info.icon}</div>
              <h1 className="font-poppins font-semibold text-[1.1rem] text-custom-maroon pb-1">
                {info.title}
              </h1>
            </div>
            <div>
              <p className="font-poppins font-light text-sm text-custom-light-brown pb-2">
                {info.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InformationSection;
