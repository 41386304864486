import React from "react";
import { useState, useEffect } from "react";

import OfferingCard from "../components/Cards/OfferingCard";
import Testimonials from "../components/Common/Testimonials";

import SubscriptionForm from "../components/Common/SubscriptionForm";
import ChadawaHeader from "../components/Chadawa/Header";

import FirstPageOutlinedIcon from "@mui/icons-material/FirstPageOutlined";
import LastPageOutlinedIcon from "@mui/icons-material/LastPageOutlined";
import ConditionalRenderer from "../devsathan_components/ConditionalRenderer";
import FAQSection from "../components/Common/FAQ";
import { ALLOFFERINGS, TESTIMONIAL } from "../rest/endpoints";
import { OFFERINGFAQ } from "../rest/endpoints";
import { apiRequest } from "../utils/apiRequest";
import { toast } from "react-toastify";
import { getAuthHeaders } from "../utils/getHeaders";

const ChadawaOfferings = () => {
  const headers = getAuthHeaders();
  const [offerings, setOfferings] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOfferings = offerings.slice(indexOfFirstItem, indexOfLastItem);

  const [faqs, setFaqs] = useState([]);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [totalPages, setTotalPages] = useState(1);
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await apiRequest({
          url: `${ALLOFFERINGS}${currentPage - 1}`,
          method: "GET",
          headers,
        });

        // Update state with fetched data
        if (result?.data?.offeringList) {
          setOfferings(result.data.offeringList);
          setTotalPages(result.data.totalPages);
        } else {
          console.error("Offerings data is not available");
          toast.error("Offerings data is unavailable.");
        }
      } catch (error) {
        console.error("Error fetching offerings:", error);
        toast.error("Failed to fetch offerings.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchfaq = async () => {
      const result = await apiRequest({
        url: OFFERINGFAQ,
        method: "GET",
        headers,
      });

      if (result?.data?.faqs && Array.isArray(result.data.faqs)) {
        setFaqs(result.data.faqs); // Set state with the fetched FAQ data
      } else {
        console.error("FAQ data is not available or not an array");
        toast.error("FAQ data is unavailable.");
      }
    };

    fetchfaq();
  }, []);

  useEffect(() => {
    const fetchTestimonials = async () => {
      const result = await apiRequest({
        url: TESTIMONIAL,
        method: "GET",
        headers,
      });

      if (result?.data?.reviews && Array.isArray(result.data.reviews)) {
        setTestimonials(result.data.reviews); // Set state with the fetched testimonial data
      } else {
        console.error("Testimonial data is not available or not an array");
        toast.error("Testimonial data is unavailable.");
      }
    };

    fetchTestimonials();
  }, []);

  return (
    <div className="min-h-screen bg-[#ffece5]">
      {isLoading ? (
        <div className=" x flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#FFB830]"></div>
          <p className="text-center text-lg font-semibold text-custom-maroon">
            Loading...
          </p>
          <div className="animate-ping rounded-full h-32 w-32 border-t-2 border-b-2 border-[#FFB830]"></div>
        </div>
      ) : !offerings ? (
        <div>No data available</div>
      ) : (
        <>
          <ChadawaHeader />
          <OfferingCard offerings={currentOfferings} />
          {totalPages > 1 && (
            <div className="flex justify-center mt-4">
              {totalPages > 2 && (
                <button onClick={() => handlePageChange(1)}>
                  <FirstPageOutlinedIcon />
                </button>
              )}
              {pageNumbers.map((number) => (
                <button
                  key={number}
                  onClick={() => handlePageChange(number)}
                  className={`mx-2 px-4 py-2 rounded-md ${
                    currentPage === number
                      ? "bg-custom-orange text-white"
                      : "bg-dark-pink"
                  }`}
                >
                  {number}
                </button>
              ))}
              {totalPages > 2 && (
                <button onClick={() => handlePageChange(totalPages)}>
                  <LastPageOutlinedIcon />
                </button>
              )}
            </div>
          )}
          <ConditionalRenderer componentKey="SubscribeLandingpage">
            <SubscriptionForm />
          </ConditionalRenderer>
          <Testimonials testimonials={testimonials} />
          <div className="pb-10 px-4">
            <FAQSection faqs={faqs} />
          </div>
        </>
      )}
    </div>
  );
};

export default ChadawaOfferings;
