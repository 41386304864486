import React, { useState, useRef, useEffect } from "react";
import logo from "../../assets/logo.svg";
import {
  NotificationsNoneOutlined,
  SearchOutlined,
  ShoppingBagOutlined,
  MenuOutlined,
  CloseOutlined,
} from "@mui/icons-material";
import { pushGTMEvent } from "../../utils/GTMEventComponent";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router";
import ConditionalRenderer from "../../devsathan_components/ConditionalRenderer";
import { loadToken } from "../../utils/localStorage";
import useDetectStorageClear from "../../utils/useClearLocalstorage";
import { Link } from "react-router-dom";
import { BOOK_PUJA } from "../../utils/gtmEventConstants";
import { BOOK_CHADAWA } from "../../utils/gtmEventConstants";

import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";

const Navbar = () => {
  const reloadKey = useDetectStorageClear();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const token = loadToken();
  const navigate = useNavigate();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Check if the click/touch is outside both menu and button
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      // Add both mouse and touch event listeners
      document.addEventListener("mousedown", handleOutsideClick);
      document.addEventListener("touchstart", handleOutsideClick);
    }

    return () => {
      // Clean up both event listeners
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("touchstart", handleOutsideClick);
    };
  }, [isOpen]);

  // Prevent menu from staying open when route changes
  useEffect(() => {
    return () => {
      setIsOpen(false);
    };
  }, []);

  const gtmnavbar = (page) => {
    if (page === "puja-listing") {
      pushGTMEvent(BOOK_PUJA);
    } else if (page === "chadawa") {
      pushGTMEvent(BOOK_CHADAWA);
    }
  };

  return (
    <header className="bg-custom-beige shadow-sm fixed w-full top-0 z-30">
      <nav key={reloadKey} className="w-full max-w-[1440px] mx-auto py-2">
        <div className="flex items-center justify-between">
          {/* Logo */}
          <div
            className="flex items-center"
            onClick={() => {
              setIsOpen(false);
              navigate("/");
            }}
          >
            <img src={logo} alt="Devsthan Logo" className="w-16 h-12" />
            <h1 className="text-[#FF6B35] text-base font-bold mt-2">
              DevSthan
            </h1>
          </div>

          {/* Desktop Navigation Links */}
          <div className="hidden md:flex items-center space-x-8">
            {/*<Link*/}
            {/*  to="#"*/}
            {/*  className="text-gray-600 hover:text-gray-900 transition-colors duration-200"*/}
            {/*>*/}
            {/*  About*/}
            {/*</Link>*/}

            <Link
              to="/puja-listing"
              onClick={() => gtmnavbar("puja-listing")}
              className="text-gray-600 hover:text-gray-900 transition-colors duration-200"
            >
              Book Puja
            </Link>
            <Link
              to="/chadawa"
              className="text-gray-600 hover:text-gray-900 transition-colors duration-200"
              onClick={() => gtmnavbar("chadawa")}
            >
              Chadawa
            </Link>
            {token && (
              <Link
                to="/orders"
                className="text-gray-600 hover:text-gray-900 transition-colors duration-200"
              >
                Bookings
              </Link>
            )}

            {/*<Link*/}
            {/*  to="#"*/}
            {/*  className="text-gray-600 hover:text-gray-900 transition-colors duration-200"*/}
            {/*>*/}
            {/*  Contact*/}
            {/*</Link>*/}
          </div>

          {/* Desktop Right Section */}
          <div className="hidden md:flex items-center space-x-4">
            <ConditionalRenderer componentKey="navbarsearchicon">
              <button className="p-2 rounded-full transition-colors duration-200 hover:bg-custom-orange hover:text-white text-gray-600">
                <SearchOutlined className="w-6 h-6" />
              </button>
            </ConditionalRenderer>
            <button
              className="p-2 rounded-full transition-colors duration-200 hover:bg-custom-orange hover:text-white text-gray-600"
              onClick={() => {
                setIsOpen(false);
                navigate("/cart");
              }}
            >
              <ShoppingBagOutlined className="w-6 h-6" />
            </button>
            {token === null ? (
              <button
                className="px-6 py-2 bg-[#3A1700] text-white rounded-md hover:bg-[#4A2800] transition-colors duration-200"
                onClick={() => {
                  setIsOpen(false);
                  navigate("/auth");
                }}
              >
                Signup
              </button>
            ) : (
              <button
                onClick={() => {
                  setIsOpen(false);
                  navigate("/profile");
                }}
              >
                <AccountCircleIcon fontSize="large" />
              </button>
            )}
          </div>

          {/* Mobile Menu */}
          <div className="flex md:hidden items-center space-x-4 pr-6">
            <ConditionalRenderer componentKey="navbarsearchicon">
              <button className="p-2 hover:bg-custom-orange hover:text-white text-gray-600 rounded-full">
                <SearchOutlined className="w-6 h-6" />
              </button>
            </ConditionalRenderer>
            <button
              className="p-2 hover:bg-custom-orange hover:text-white text-gray-600 rounded-full"
              onClick={() => {
                setIsOpen(false);
                navigate("/cart");
              }}
            >
              <ShoppingBagOutlined className="w-6 h-6" />
            </button>
            <button
              ref={buttonRef}
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(!isOpen);
              }}
              className="p-2 hover:bg-custom-orange hover:text-white text-gray-600 rounded-full"
            >
              {isOpen ? (
                <CloseOutlined className="w-6 h-6" />
              ) : (
                <MenuOutlined className="w-6 h-6" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Menu Dropdown */}
        {isOpen && (
          <div
            ref={menuRef}
            className="md:hidden pt-4 pb-6 bg-white border-t mt-4 z-50"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex flex-col space-y-4">
              {/*<Link*/}
              {/*  to="#"*/}
              {/*  className="text-gray-600 hover:text-gray-900 px-4 py-2 hover:bg-gray-50"*/}
              {/*  onClick={() => setIsOpen(false)}*/}
              {/*>*/}
              {/*  About*/}
              {/*</Link>*/}
              <Link
                to="/puja-listing"
                className="text-gray-600 hover:text-gray-900 px-4 py-2 hover:bg-gray-50"
                onClick={() => {
                  setIsOpen(false);
                  gtmnavbar("puja-listing");
                }}
              >
                Book Puja
              </Link>
              <Link
                to="/chadawa"
                className="text-gray-600 hover:text-gray-900 px-4 py-2 hover:bg-gray-50"
                onClick={() => {
                  setIsOpen(false);
                  gtmnavbar("chadawa");
                }}
              >
                Chadawa
              </Link>
              {token && (
                <Link
                  to="/orders"
                  className="text-gray-600 hover:text-gray-900 px-4 py-2 hover:bg-gray-50"
                  onClick={() => setIsOpen(false)}
                >
                  Bookings
                </Link>
              )}

              {/*<Link*/}
              {/*  to="/orders"*/}
              {/*  className="text-gray-600 hover:text-gray-900 px-4 py-2 hover:bg-gray-50"*/}
              {/*  onClick={() => setIsOpen(false)}*/}
              {/*>*/}
              {/*  Bookings*/}
              {/*</Link>*/}
              {/*<Link*/}
              {/*  to="#"*/}
              {/*  className="text-gray-600 hover:text-gray-900 px-4 py-2 hover:bg-gray-50"*/}
              {/*  onClick={() => setIsOpen(false)}*/}
              {/*>*/}
              {/*  Contact*/}
              {/*</Link>*/}
              <div className="px-4">
                {!!token ? (
                  <button
                    className="w-full px-6 py-2 bg-[#3A1700] text-white rounded-md hover:bg-[#4A2800] transition-colors duration-200"
                    onClick={() => {
                      setIsOpen(false);
                      navigate("/profile");
                    }}
                  >
                    <span className="pr-2">Profile</span>
                  </button>
                ) : (
                  <button
                    className="w-full px-6 py-2 bg-[#3A1700] text-white rounded-md hover:bg-[#4A2800] transition-colors duration-200"
                    onClick={() => {
                      setIsOpen(false);
                      navigate("/auth");
                    }}
                  >
                    Signup
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Navbar;
