import React from "react";

import DateSection from "../Cards/offeringCardComponents/DateSection";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import ImageSection from "../Common/ImageSection_courosel";

const Details = ({ pooja }) => {
  const date = new Date(pooja.eventDateTime);
  const time = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  const location = [
    pooja?.mainSection?.location?.address,
    pooja?.mainSection?.location?.city,
    pooja?.mainSection?.location?.state,
    pooja?.mainSection?.location?.country,
    pooja?.mainSection?.location?.pincode
  ]
    .filter((item) => item) // Remove null, undefined, and empty values
    .join(", "); // Join with ", " ensuring no extra commas

  return (
    <div className="bg-inherit p-6 rounded-lg shadow-md mx-auto pb-10 sm:pb-24">
      <div className="flex flex-col md:flex-row items-start justify-start">
        {/* Image Section */}
        <div className="mb-2  sm:pl-14 sm:pr-16">
          {/* <img
            src={pooja?.mainSection?.titleImageInfo?.url}
            alt={pooja?.mainSection?.titleImageInfo?.altText}
            className="rounded-lg w-[80vw] h-[55vw] mx-auto sm:w-[52vw] sm:h-[28vw]"
          /> */}

          <ImageSection   imagesa={pooja?.mainSection?.imageInfoList} />
        </div>

        {/* Content Section */}
        <div className="px-4 sm:max-w-[30vw] flex flex-col items pt-4">
          <DateSection offering={pooja.mainSection} />

          <h2 className="text-3xl md:text-4xl lg:text-4xl text-custom-maroon font-poppins font-semibold sm:mt-6 mt-4 leading-[1.3]">
            {pooja?.mainSection?.name}
          </h2>

          <p className="text-md md:text-lg lg:text-base text-custom-brown mb-3 mt-4 text-base sm:text-sm xs:text-xs">
            {pooja?.mainSection?.shortDescription}
          </p>

          <div className="flex items-center text-gray-600 ">
            <div className="pr-2 text-custom-gray">
              <FmdGoodOutlinedIcon />
            </div>
            <h1 className="font-poppins font-medium text-sm text-custom-light-brown">
              {location}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
