import React from "react";
import { Link } from "react-router-dom";
const ParticularChadawaHeader = ({ particularChadawa }) => {
  return (
    <div>
      <div className="px-8">
        <div className="text-sm pt-7 lg:px-6 text-gray-600 mt-16 font-poppins font-bold">
          {/* <Link to="/" className="text-custom-brown">
            <span>Home</span>
          </Link>
          <span className="px-2">/</span>
          <Link to="/chadawa" className="text-custom-brown">
            <span>Chadawa</span>
          </Link>
          <span className="mx-2 font-poppins font-semibold">/</span>
          <span className="font-poppins font-semibold text-custom-orange">
            {particularChadawa.name}
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default ParticularChadawaHeader;
