import React, { useState } from "react";

import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
const TestimonialCardItem = ({ testimonial, index, isVisible }) => {
  const getYouTubeID = (url) => {
    const regExp =
      /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/;
    const match = url.match(regExp);
    return match ? match[1] : null;
  };
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = (e) => {
    e.stopPropagation();
    const video = e.currentTarget.nextElementSibling; // Get the video element
    if (video) {
      video.play();
      setIsPlaying(true);
    }
  };

  const isImage = (url) => {
    return /\.(jpeg|jpg|gif|png|webp|avif|svg)$/i.test(url);
  };
  return (
    <div
      data-index={index}
      className={`testimonial-card flex-shrink-0 w-full sm:w-[48rem] bg-custom-beige rounded-[40px] p-3 sm:p-7 flex flex-col justify-between transition-all duration-500
      ${isVisible ? "opacity-100 scale-100" : "opacity-100 scale-95"}`}
    >
      <div className="flex flex-col sm:flex-row gap-6">
        <div className="flex-1">
          <div className="w-full h-0 pb-[75%] relative rounded-3xl overflow-hidden">
            {isImage(testimonial.videoInfo.url) ? (
              <img
                src={testimonial.videoInfo.url}
                alt="Testimonial"
                className="absolute w-full h-full object-cover rounded-3xl"
              />
            ) : testimonial.videoInfo.url.includes("youtube.com") ||
              testimonial.videoInfo.url.includes("youtu.be") ? (
              <iframe
                className="absolute w-full h-full rounded-3xl"
                src={`https://www.youtube.com/embed/${getYouTubeID(
                  testimonial.videoInfo.url
                )}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            ) : (
              <div className="absolute w-full h-full">
                {!isPlaying && (
                  <button
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-3xl cursor-pointer"
                    onClick={handlePlayClick}
                  >
                    <PlayCircleOutlineIcon
                      style={{ fontSize: 80, color: "white", opacity: 0.8 }}
                      className="hover:opacity-100 transition duration-300"
                    />
                  </button>
                )}
                <video
                  className="absolute w-full h-full rounded-3xl object-cover"
                  controls
                  playsInline
                >
                  <source src={testimonial.videoInfo.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
          </div>
        </div>
        {testimonial.description && (
          <div className="flex-1">
            <p className="text-custom-medium-brown font-poppins font-normal text-[1rem] text-left">
              {testimonial.description}
            </p>
          </div>
        )}
      </div>

      <div className="user_details mt-5 text-left flex items-center">
        <img
          src={testimonial.image.url}
          alt="Profile"
          className="w-16 h-16 rounded-full mr-4"
        />
        <div>
          <h3 className="text-xl font-poppins font-semibold text-custom-maroon">
            {testimonial.name}
          </h3>
          <p className="text-sm font-poppins text-custom-maroon">
            {testimonial.location.address || "Devotee"} |{" "}
            {testimonial.location.city || "Devotee"} |{" "}
            {testimonial.location.country || "Devotee"} |{" "}
            {testimonial.location.pincode || "Devotee"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCardItem;
