import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  LocalShippingRounded,
  CreditCardRounded,
  ReceiptLongRounded,
  TimelineRounded,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { GTEORDERDETILSBYID } from "../rest/endpoints";
import { PlayCircle } from "@mui/icons-material";
import { getAuthHeaders } from "../utils/getHeaders";
import { apiRequest } from "../utils/apiRequest";

const OrderDetailsPage = () => {
  const [activeSection, setActiveSection] = useState("summary");
  const Navigate = useNavigate();
  const headers = getAuthHeaders();

  const [isPlaying, setIsPlaying] = useState(false);
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);

  useEffect(() => {
    const ordetailsbyid = async () => {
      try {
        const response = await apiRequest({
          url: GTEORDERDETILSBYID + id,
          method: "GET",
          headers,
        });
        const data = response;
        console.log("orderdetailsdata",data);
       setOrderDetails(data.data);
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    ordetailsbyid();
  }, [id]);

  
  const handlePlayClick = (e) => {
    e.stopPropagation();
    const video = e.currentTarget.nextElementSibling;
    if (video) {
      video.play();
      setIsPlaying(true);
    }
  };
 
  const NavButton = ({ section, label, icon }) => (
    <button
      onClick={() => setActiveSection(section)}
      className={`flex items-center gap-2 transition-all duration-300
        ${
          activeSection === section
            ? "bg-orange-100 text-orange-600"
            : "text-custom-maroon hover:bg-orange-50"
        }
        // Mobile styles
        w-full sm:w-auto justify-center sm:justify-start
        p-3 sm:px-6 sm:py-3 
        text-xs sm:text-sm font-medium`}
    >
      {icon}
      <span className="hidden sm:inline">{label}</span>
      {/* Mobile-only label below icon */}
      <span className="text-[10px] sm:hidden">{label}</span>
    </button>
  );

  const OrderStatus = () => (
    <div className="flex flex-col sm:flex-row justify-between mb-2 w-full space-y-2 sm:space-y-0 sm:space-x-2">
  {/* Left Half */}
  <div className="w-full sm:w-1/2 p-4 bg-white rounded-lg shadow">
    <div className="flex justify-between items-center">
      <p className="text-sm text-custom-gray">Order Date</p>
      <p className="font-medium text-custom-maroon text-right">
        {orderDetails?.orderDate
          ? new Date(orderDetails.orderDate).toLocaleString('en-US',
              {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour12: true,
              }
            )
          : 'Order Date is pending'}
      </p>
    </div>
  </div>
  
  {/* Right Half */}
  <div className="w-full sm:w-1/2 p-4 bg-white rounded-lg shadow">
    <div className="flex justify-between items-center">
      <p className="text-sm text-custom-gray">Order Type</p>
      <span className="font-medium text-custom-maroon text-right">
        {orderDetails?.type || 'Type is pending'}
      </span>
    </div>
  </div>
</div>
  );
  const OrderType = () => (
    <div className="flex flex-col sm:flex-row justify-between mb-4 w-full space-y-2 sm:space-y-0 sm:space-x-2">
      {/* Left Half */}
      <div className="w-full sm:w-1/2 p-4 bg-white rounded-lg shadow">
        <div className="space-y-1 flex justify-between items-center">
          <p className="text-sm text-custom-gray">Order Status</p>
          <div className="flex  items-center space-x-2">
            <div className="w-2 h-2 bg-green-600 rounded-full" />
            <span className="font-medium text-custom-maroon text-right">
              {orderDetails?.orderDisplayName || 'Devotte Name is pending'}
            </span>
          </div>
        </div>
      </div>
      
      {/* Right Half */}
      <div className="w-full sm:w-1/2 p-4 bg-white rounded-lg shadow">
    <div className="flex justify-between items-center">
      <p className="text-sm text-custom-gray">Total Amount</p>
      <span className="font-medium text-custom-maroon text-right">
        ₹ {orderDetails?.totalAmount || 'Amount is pending'}
      </span>
    </div>
  </div>
    </div>
  );


  const OrderItems = () => (
    <div className="space-y-2">
      {/* Devote Name Card */}
      <div className="bg-white rounded-lg shadow-sm  px-4 py-2">
        <h3 className="text-gray-800 font-medium">Devote Name</h3>
        <p className="text-gray-700 mt-2">
  {orderDetails?.userInfo?.name}
  {orderDetails?.userInfo?.additionalNames && orderDetails.userInfo.additionalNames.length > 0 && 
    `, ${orderDetails.userInfo.additionalNames.join(', ')}`
  }
</p>
      </div>
      
      {/* Item Cards - Using existing data structure */}
      {orderDetails?.items.map((item, index) => (
  <motion.div
    key={index}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: index * 0.1 }}
    className="bg-white rounded-lg shadow-sm px-4 py-2"
  >
    <div className="flex justify-between items-center">
      <h3 className="text-gray-800 font-medium">{item.catalogName}</h3>
      <p className="text-gray-900 font-medium">₹ {item.itemAmount}</p>
    </div>
    
    <div className="mt-4 space-y-2">
      <div className="flex justify-between items-center">
        <p className="text-gray-700">Quantity</p>
        <p className="text-gray-900">{item.quantity}</p>
      </div>
      
      {/* Subtle line above total */}
      <div className="border-t border-gray-100 my-1"></div>
      
      <div className="flex justify-between items-center pt-1">
        <p className="text-gray-700">Subtotal</p>
        <p className="text-gray-900">₹ {item.totalAmount}</p>
      </div>
      
    </div>
    
  </motion.div>
))}
<div className="bg-white rounded-lg shadow-sm  px-4 py-2">
  <div className="flex justify-between items-center pt-1">
        <h3 className="text-gray-800 font-medium">Total Amount</h3>
        <p className="text-gray-700 mt-2">
          ₹ {orderDetails?.totalAmount || 'Amount is pending'}
  
</p>
</div>
      </div>
      
      {/* If no items present, show a fallback for preview */}
      {orderDetails?.items.length === 0 && (
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h3 className="text-gray-800 font-medium">Items</h3>
          <p className="text-gray-700 mt-2">No items in this order</p>

        </div>


          )}
              </div>
  );

  const TrackingTimeline = () => (
    <div className="space-y-2 px-2">
      {orderDetails?.orderProcessingSteps
        .filter((step) => step.displayInUI)
        .map((step, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.1 }}
            className="flex items-start space-x-4"
          >
            <div className="relative flex items-center justify-center">
              <div className="w-3 h-3 bg-custom-brown rounded-full" />
              {index !==
                orderDetails?.orderProcessingSteps.filter(
                  (step) => step.displayInUI
                ).length -
                  1 && (
                <div className="absolute w-0.5 h-16 bg-light-pink top-3 left-1/2 transform -translate-x-1/2" />
              )}
            </div>
            <div className="flex-1">
              <p className="font-medium text-custom-maroon">
                {step.processingTypeDisplayName}
              </p>
              <p className="text-xs sm:text-sm text-custom-gray">
                {step.processingTypeDescription}
              </p>
              <p className="text-xs sm:text-sm text-custom-gray">
                {new Date(step.startedAt).toLocaleString()}
              </p>
            </div>
          </motion.div>
        ))}
    </div>
  );

  const ShippingSection = () => {
    // Function to display address information professionally
    const formatAddress = () => {
      const location = orderDetails?.shippingDetails?.location;

      
      if (!location) return "Address information pending";
      
      const addressParts = [
        location.address,
        location.city, 
        location.state,
        location.country,
        location.pincode
      ].filter(Boolean);
      
      return addressParts.length > 0 ? addressParts.join(', ') : "Address information pending";
    };

    const checkPrasad = () => {
      const items = orderDetails?.items;
      if (!items || items.length === 0) return false; // Return false if no items
  
      // Find an item with catalogSubType 'PRASAD'
      const prasadItem = items.find(item => item.catalogSubType === 'PRASAD');
      return prasadItem !== undefined; // Return true if found, false otherwise
    };
  
    return (
      <div className="bg-white rounded-lg shadow-sm px-4 py-2">
  {/* Only display shipping details if "PRASAD" is in the order */}
  {checkPrasad() ? (
    <>
      <h3 className="text-gray-800 font-medium">Delivery Address</h3>
      <p className="text-gray-700 mt-2 break-words">
        {formatAddress()}
      </p>
      {/* You can add other details (e.g., shipping method, tracking) here if needed */}
    </>
  ) : (
    <>
      <h3 className="text-gray-800 font-medium">Delivery</h3>
      <p className="text-gray-700 mt-2">
      You did not opt for prasad in this order.
      </p>
    </>
  )}
</div>
    );
  };


  const PaymentSection = () => {
    return orderDetails?.paymentDetails?.paymentId ? (
      <div className="space-y-2">
        {/* Payment Method */}
        <div className="bg-white rounded-lg shadow-sm px-4 py-2">
          <h3 className="text-gray-800 font-medium">Payment Method</h3>
          <p className="text-gray-700 mt-2">
            {orderDetails?.paymentDetails.paymentMethod || 'Payment method pending'}
          </p>
        </div>
  
        {/* Payment ID */}
        <div className="bg-white rounded-lg shadow-sm px-4 py-2">
          <h3 className="text-gray-800 font-medium">Payment Id</h3>
          <p className="text-gray-700 mt-2">
            {orderDetails?.paymentDetails.paymentId || 'Payment ID pending'}
          </p>
        </div>
  
        {/* Payment Status */}
        <div className="bg-white rounded-lg shadow-sm px-4 py-2">
          <h3 className="text-gray-800 font-medium">Payment Status</h3>
          <p className="text-gray-700 mt-2">
            {orderDetails?.paymentDetails.message || 'Payment status pending'}
          </p>
        </div>
  
        {/* Total in its own card */}
        <div className="bg-white rounded-lg shadow-sm px-4 py-2">
          <div className="flex justify-between items-center">
            <p className="text-gray-800 font-medium">Total</p>
            <p className="text-gray-900 font-medium text-xl">
              ₹ {orderDetails?.totalAmount}
            </p>
          </div>
        </div>
      </div>
    ) : (
      <div className="bg-white rounded-lg shadow-sm px-4 py-2">
        <h3 className="text-gray-800 font-medium">Payment Status</h3>
        <p className="text-gray-700 mt-2">We have not received your payment.</p>
      </div>
    );
  };
  
  const sections = {
    summary: <OrderItems />,
    shipping: <ShippingSection />,
    tracking: <TrackingTimeline />,
    payment: <PaymentSection />,
  };

  return (
    <div className="min-h-screen bg-custom-beige py-6 sm:py-12 px-4 sm:px-6 lg:px-8 mt-16 md:mt-10">
      <div className="max-w-3xl mx-auto">
        <div className="bg-gradient-to-r from-orange-500 to-orange-400  rounded-3xl p-6 sm:p-8 mb-6 sm:mb-8 text-custom-beige">
          <h1 className="text-xl sm:text-3xl font-bold mb-2"> {orderDetails?.eventName || 'Not Available'}</h1>
          <p className="text-sm sm:text-base text-white">
            {orderDetails?.orderId || 'N/A'}
  {/* {orderDetails?.orderDate
    ? new Date(orderDetails.orderDate).toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
      })
    : 'N/A'} */}
</p>

        </div>

        <OrderStatus />
        <OrderType />

        {/* Mobile-optimized navigation */}
        <nav className="grid grid-cols-4 mb-4  overflow-hidden border border-gray-100 bg-white">
  <NavButton
    section="summary"
    label="Summary"
    icon={<ReceiptLongRounded className="w-5 h-5" />}
    className="flex flex-col items-center justify-center py-3 bg-amber-50 text-orange-500 border-r border-gray-100"
  />
  <NavButton
    section="shipping"
    label="Shipping"
    icon={<LocalShippingRounded className="w-5 h-5" />}
    className="flex flex-col items-center justify-center py-3 bg-white text-gray-600 border-r border-gray-100"
  />
  <NavButton
    section="tracking"
    label="Tracking"
    icon={<TimelineRounded className="w-5 h-5" />}
    className="flex flex-col items-center justify-center py-3 bg-white text-gray-600 border-r border-gray-100"
  />
  <NavButton
    section="payment"
    label="Payment"
    icon={<CreditCardRounded className="w-5 h-5" />}
    className="flex flex-col items-center justify-center py-3 bg-white text-gray-600"
  />
</nav>
        <motion.div
          key={activeSection}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className="space-y-6 mb-4 "
        >
          {sections[activeSection]}
        </motion.div>

       {/* Video/Image Section */}
<div className="mb-12">
  {orderDetails?.orderStatus === "COMPLETED" ? (
    <div className="relative w-full h-0 pb-[56.25%] rounded-2xl overflow-hidden shadow-xl">
      {!isPlaying && (
        <button
          className="absolute inset-0 flex items-center justify-center bg-black/50 z-10 group"
          onClick={handlePlayClick}
        >
          <PlayCircle className="w-16 h-16 text-white opacity-80 group-hover:opacity-100 group-hover:scale-110 transition-all duration-300" />
        </button>
      )}
      <video
        className="absolute inset-0 w-full h-full object-cover rounded-2xl"
        controls
        playsInline
      
      >
        <source src={orderDetails?.videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  ) : (
    <div className="relative w-full h-0 pb-[56.25%] rounded-2xl overflow-hidden shadow-xl">
      <img
        src={orderDetails?.image}
        alt={orderDetails?.name}
        className="absolute inset-0 w-full h-full object-cover rounded-2xl"
      />
      <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/40 text-white p-6 text-center">
        <div className="bg-white/10 backdrop-blur-sm p-4 rounded-lg border border-white/20">
          <h3 className="text-xl font-semibold mb-2">Your puja is in process</h3>
          <p>Once completed, your video will be available here.</p>
        </div>
      </div>
    </div>
  )}
</div>



        <div className="mt-8 sm:mt-12 text-center">
          <button
            onClick={() => Navigate("/orders")}
            className=" -mt-96  w-full sm:w-auto bg-[#FF5C35] text-custom-beige px-6 sm:px-8 py-3 sm:py-4 rounded-full font-medium hover:shadow-lg transform hover:scale-105 transition-all duration-300"
          >
            View More Orders
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsPage;
