import React from "react";
import PujaEventList from "./pujaeventlist";
import PujaEventSlider from "./pujaeventslider";

const PujaEventDisplay = ({ events, viewType }) => {
  return (
    <div className="">
      {viewType === "slider" ? (
        <PujaEventSlider events={events} />
      ) : (
        <PujaEventList events={events} />
      )}
    </div>
  );
};

export default PujaEventDisplay;
