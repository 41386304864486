import React from "react";

import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";

const TitleAndLocation = ({ offering }) => {
  const locationl = offering?.location;

  return (
    <div className="pt-4">
      <h3 className="text-xl font-semibold text-custom-maroon font-poppins">
        {offering?.name}
      </h3>
      <p className="text-sm text-custom-gray pt-1 font-poppins line-clamp-3">
        {offering?.shortDescription}
      </p>
      <div className="flex items-center text-sm text-custom-light-brown my-2">
        <span role="img" aria-label="location">
          <FmdGoodOutlinedIcon />
        </span>
        <span className="ml-1">{locationl?.address}</span>
        <span className="ml-1">{locationl?.pincode}</span>
      </div>
    </div>
  );
};

export default TitleAndLocation;
