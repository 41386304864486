import React, { useState } from "react";

const SubscriptionForm = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      console.log("Email submitted:", email);
      setEmail("");
    }
  };

  return (
    <div className="px-6 sm:mx-16">
      <div className="w-full mx-auto text-center p-8 sm:p-16 bg-custom-beige mt-12 sm:mt-24 rounded-[24px]">
        <h1 className="text-2xl md:text-[2rem] lg:text-[2.8rem] font-poppins font-medium text-custom-gray mb-3">
          Stay Connected with Devsthan
        </h1>
        <p className="text-base sm:text-xl text-custom-maroon">
          Join thousands of devotees who trust us for their spiritual journey.
        </p>

        <form
          onSubmit={handleSubmit}
          className="mt-7 flex flex-col sm:flex-row gap-4 max-w-md sm:max-w-2xl mx-auto"
        >
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="flex-1 px-4 py-3 sm:px-6 sm:py-4 rounded-[20px] bg-custom-light-beige text-sm sm:text-lg placeholder:text-[#2E181466] focus:outline-none"
            required
          />
          <button
            type="submit"
            className="px-6 py-3 sm:px-12 sm:py-4 bg-[#FF5722] text-white text-sm sm:text-lg font-medium rounded-full hover:bg-[#F04E1B] transition-colors duration-200"
          >
            Subscribe
          </button>
        </form>
      </div>
    </div>
  );
};

export default SubscriptionForm;
