import React from "react";
import TestimonialCardItem from "./TestimonialCardItem";

const TestimonialList = ({
  testimonials,
  visibleItems,
  sliderRef,
  handleDragStart,
  handleDragMove,
  handleDragEnd,
}) => {
  return (
    <div
      ref={sliderRef}
      className="sm:px-10 py-4 mx-6 flex sm:gap-2 overflow-x-hidden cursor-grab active:cursor-grabbing"
      onMouseDown={(e) => handleDragStart(e.pageX)}
      onMouseMove={(e) => handleDragMove(e.pageX)}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
      onTouchStart={(e) => handleDragStart(e.touches[0].pageX)}
      onTouchMove={(e) => handleDragMove(e.touches[0].pageX)}
      onTouchEnd={handleDragEnd}
    >
      {testimonials.map((testimonial, index) => (
        <TestimonialCardItem
          key={index}
          testimonial={testimonial}
          index={index}
          isVisible={visibleItems.has(index.toString())}
        />
      ))}
    </div>
  );
};

export default TestimonialList;
