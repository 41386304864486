import React from "react";

const ExploreMoreCard = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="flex flex-col w-full sm:w-[350px] max-w-lg bg-custom-beige rounded-2xl overflow-hidden shadow-xl transform hover:scale-105 transition-transform duration-300 ease-in-out cursor-pointer"
    >
      {/* Top Section - Image or Background */}
      <div className="h-48 bg-dark-pink flex items-center justify-center">
        {/* Dotted Circle with Icon */}
        <div className="w-36 h-36 rounded-full border-4 border-dashed border-[#FF5722] flex items-center justify-center">
          {/* Plus Icon */}
          <svg
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            className="text-[#FF5722]"
          >
            <path
              d="M12 4V20M4 12H20"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>

      {/* Content Section */}
      <div className="p-6 text-center">
        <h3 className="text-xl font-semibold text-[#3A0B01] mb-2 text-wrap">
          Discover More Events
        </h3>
        <p className="text-sm text-[#6A4F4B] mb-4 text-wrap">
          Explore exciting opportunities, events, and more!
        </p>
        <button
          className=" p-7 bg-[#FF5722] text-white py-2 rounded-full hover:bg-[#E64A19] transition-colors duration-300 ease-in-out"
          onClick={onClick}
        >
          Explore Now
        </button>
      </div>
    </div>
  );
};

export default ExploreMoreCard;
