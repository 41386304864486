import { useState } from "react";
import SignUp from "./SignUp";
import OTPVerification from "./Otp";

import { loadToken } from "../../utils/localStorage";
import { openModal } from "../../utils/openModal";

const AuthModal = ({ isOpen, onClose }) => {
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  const token = loadToken();

  if (!isOpen) {
    openModal({ value: false });
    return;
  }

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
      onClick={onClose} // Close modal when clicking outside
    >
      <div
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
      >
        {isOtpVerified ? (
          <SignUp userDetails={userDetails} />
        ) : (
          <OTPVerification
            setUserDetails={setUserDetails}
            setIsOtpVerified={setIsOtpVerified}
          />
        )}
      </div>
    </div>
  );
};

export default AuthModal;
