import React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";

function Filters() {
  const buttonData = [
    { id: 1, icon: "🙏", text: "Puja", showIconAsEmoji: true },
    { id: 2, icon: <DateRangeOutlinedIcon />, text: "Current Week" },
    { id: 3, icon: <NearMeOutlinedIcon />, text: "Multiple Locations" },
    { id: 4, icon: <AccessTimeIcon />, text: "Evening" },
  ];

  return (
    <div className=" flex flex-wrap justify-center   w-full sm:justify-start gap-4  mb-12 sm:mb-32 sm:pl-[2vw] py-2  ">
      <h1 className="hidden xl:block text-custom-maroon text-2xl py-2 rounded-lg items-center gap-2 font-poppins font-medium">
        <span >Filters:</span>
      </h1>

      <div className="flex gap-4 justify-center sm:justify-start sm:pl-[4%]">
        {buttonData.map((button) => (
          <button
            key={button.id}
            className="bg-custom-maroon max-w-[20vw] text-white px-2 sm:px-4 py-2 mt-2 rounded-lg flex items-center gap-2 font-poppins h-[2.5rem] text-sm sm:text-base"
          >
            <span>
              {button.showIconAsEmoji ? button.icon : button.icon || null}
            </span>
            <span
              className="hidden sm:block text-ellipsis overflow-hidden whitespace-nowrap"
              style={{ fontSize: "clamp(0.75rem, 2vw, 1rem)" }}
            >
              {button.text}
            </span>
            <KeyboardArrowDownIcon />
          </button>
        ))}
      </div>
    </div>
  );
}

export default Filters;
