import { withRetry } from "./retryApi";
import { toast } from "react-toastify";
import { openModal } from "./openModal";

export const apiRequest = async ({
  url,
  method = "GET",
  data = null,
  headers = { "Content-Type": "application/json" },
  retries = 3,
  delay = 1000,
  onRetry = null,
}) => {
  try {
    return await withRetry({
      operation: async () => {
        const response = await fetch(url, {
          method,
          headers,
          body: data ? JSON.stringify(data) : null,
        });

        if (response.status === 401 || response.status === 403) {
          localStorage.clear();
          openModal({ value: true });
          return { showModal: true };
        }

        let responseData;
        try {
          responseData = await response.json();
        } catch {
          responseData = {}; // Handle cases where response isn't valid JSON
        }

        if (!response.ok) {
          const errorMessage =
            responseData?.message || `HTTP error! Status: ${response.status}`;

          throw new Error(errorMessage); // Throw error to trigger retry
        }

        return responseData;
      },
      retries,
      delay,
      onRetry: (attempt, error) => {
        console.warn(`Retry attempt ${attempt}: ${error.message}`);
      },
    });
  } catch (error) {
    toast.error(`Request failed: ${error.message}`); // Only show error when all retries fail
    return null;
  }
};
