import React from "react";
import { useNavigate } from "react-router-dom";
import PujaEventCard from "./pujaeventcard";

const PujaEventList = ({ events }) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 9;
  const totalPages = Math.ceil(events.length / itemsPerPage);
  console.log("puja event list", events);

  const navigate = useNavigate();
  const displayedEvents = React.useMemo(() => {
    return events.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  }, [events, currentPage]);

  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);

      // Scroll to the top of the page or to the top of the event list
      const eventsContainer = document.getElementById("events-container");
      if (eventsContainer) {
        eventsContainer.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  // const handleMoreInfoClick = (id) => {
  //   // Navigate to the new page with the event ID
  //   navigate(`/puja/${pujaEvent?.mainSection?.name}`);
  // };
  
  return (
    <div className="max-w-full">
      <div id="events-container" className="w-full pt-8 pb-4">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          {displayedEvents.map((pujaEvent) => (
            <div key={pujaEvent.id} className="flex justify-center mb-5">
              <PujaEventCard
                pujaEvent={pujaEvent}
                onButtonClick={() =>
                  
                  navigate(`/puja/${pujaEvent?.mainSection?.name.replace(/\s+/g, "_")}`)

                }
                className="w-full max-w-sm"
              />
            </div>
          ))}
        </div>
      </div>

      <div className="text-center max-w-full">
        <button
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 mx-1 bg-custom-beige rounded disabled:opacity-50"
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, index) => index + 1).map(
          (page) => (
            <button
              key={page}
              onClick={() => goToPage(page)}
              className={`px-4 py-2 mx-1 rounded ${
                currentPage === page ? "bg-blue-500 text-white" : "bg-gray-200"
              }`}
            >
              {page}
            </button>
          )
        )}
        <button
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-4 py-2 mx-1 bg-gray-200 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PujaEventList;
