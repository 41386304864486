import React from "react";
import { useState, useEffect } from "react";
import { getAuthHeaders } from "../utils/getHeaders";
import {
  CART,
  PARTICULARCHADAWABYID,
  PARTICULARPUJABYID,
} from "../rest/endpoints";
import { apiRequest } from "../utils/apiRequest";
import { useDispatch } from "react-redux";
import ReviewCartItems from "../components/Cart/ReviewCartItems";
import OrderDetails from "../components/Cart/OrderDetails";
import { setPackage } from "../redux/reducers/pujaPackage";

const CommonCart = () => {
  const headers = getAuthHeaders();
  const [type, setType] = useState("");
  const [cart, setCart] = useState([]);
  const [parentId, setParentId] = useState("");
  const [parentData, setParentData] = useState({});
  const [reload, setReload] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const getcartItems = async () => {
      try {
        const response = await apiRequest({
          url: CART,
          method: "GET",
          headers,
        });

        if (response?.data) {
          setParentId(response.data.parentId || null);
          setCart(response.data);
          setType(response.data.type || null);
        } else {
          setCart(null); // Or an empty array if cart is expected to be an array
          setParentId(null);
          setType(null);
        }
      } catch (error) {
        console.error("Error fetching cart items:", error);
        setCart(null); // Handle error by setting cart as empty
      }
    };

    getcartItems();
  }, [reload]);

  useEffect(() => {
    const fetchMainData = async () => {
      let url;
      if (type === "PUJA") {
        url = PARTICULARPUJABYID;
      } else if (type === "OFFERING") {
        url = PARTICULARCHADAWABYID;
      } else {
        return;
      }
      const response = await apiRequest({
        url: `${url}${parentId}`,
        method: "GET",
        headers,
      });
      setParentData(response?.data);
    };

    fetchMainData();
  }, [cart, type, parentId, reload]);

  useEffect(() => {
    const fetchData = async () => {
      if (type === "PUJA" && parentData?.pujaPackageList) {
        try {
          const pujaPackageList = await parentData.pujaPackageList; // Ensure data is resolved

          const temp = cart.itemList
            .filter((item) => item.subType === "PUJA_PACKAGE")
            .map((item) => {
              const pujaPackage = pujaPackageList.find(
                (puja) => puja.name === item.name
              );
              return { id: pujaPackage?.id ?? null, name: item.name };
            });

          if (temp.length > 0) {
            dispatch(setPackage(temp[0]));
          }
        } catch (error) {
          console.error("Error fetching pujaPackageList:", error);
        }
      }
    };

    fetchData();
  }, [cart, reload, type, parentData]);

  return (
    <div className="min-h-screen bg-light-pink flex justify-center items-center pt-24 pb-16">
      <div className="flex flex-wrap md:flex-nowrap w-full max-w-6xl md:rounded-[24px] lg:rounded-[24px] md:pl-10 md:pr-10 lg:pl-10 lg:pr-10">
        {!cart ||
        !Array.isArray(cart.itemList) ||
        cart.itemList.length === 0 ? (
          <div className="flex justify-center items-center font-bold text-xl w-screen text-center">
            Puja Basket is Empty
          </div>
        ) : (
          <div className="flex flex-col md:flex-row w-full">
            <div className="w-full sticky top-14 z-20 h-full">
              <ReviewCartItems
                cart={cart}
                type={type}
                parentData={parentData}
              />
            </div>
            <OrderDetails
              type={type}
              chadawa={parentData}
              currentCart={cart}
              reload={reload}
              setReload={setReload}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CommonCart;
