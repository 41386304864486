const BOOK_PUJA = 'BOOK_PUJA';
const BOOK_CHADAWA = 'BOOK_CHADAWA';
const BOOK_PUJA_PARTICIPATE_LANDING_PAGE = 'BOOK_PUJA_PARTICIPATE_LANDING_PAGE';
const BOOK_CHADAWA_PARTICIPATE_LANDING_PAGE = 'BOOK_CHADAWA_PARTICIPATE_LANDING_PAGE';


//////////////////////////////////

// BOOK_PUJA_PARTICIPATE_NOW_PARTICULAR_PAGE
// BOOK_PUJA_PARTICIPATE_NOW_SELECT_PACKAGE_PARTICULAR_PAGE
// SEND_OTP
// BOOKING_CHECKOUT_BUTTON
// PAYMENT_STATUS

// OFFER_CHADAWA_PARTICIPATE_LANDING_PAGE
// BOOK_CHADAWA_PARTICIPATE_NOW_PARTICULAR_PAGE

// BOOKING_CHECKOUT_BUTTON

const BOOK_PUJA_PARTICIPATE_NOW_PARTICULAR_PAGE = 'BOOK_PUJA_PARTICIPATE_NOW_PARTICULAR_PAGE';
const BOOK_PUJA_PARTICIPATE_NOW_SELECT_PACKAGE_PARTICULAR_PAGE = 'BOOK_PUJA_PARTICIPATE_NOW_SELECT_PACKAGE_PARTICULAR_PAGE';
const SEND_OTP = 'SEND_OTP';
const BOOKING_CHECKOUT_BUTTON = 'BOOKING_CHECKOUT_BUTTON';
const PAYMENT_STATUS = 'PAYMENT_STATUS';
const OFFER_CHADAWA_PARTICIPATE_LANDING_PAGE = 'OFFER_CHADAWA_PARTICIPATE_LANDING_PAGE';
const BOOK_CHADAWA_PARTICIPATE_NOW_PARTICULAR_PAGE = 'BOOK_CHADAWA_PARTICIPATE_NOW_PARTICULAR_PAGE';
const COMPLETE_REGISTRATION = 'COMPLETE_REGISTRATION';



export { BOOK_PUJA, BOOK_CHADAWA, BOOK_PUJA_PARTICIPATE_LANDING_PAGE, BOOK_CHADAWA_PARTICIPATE_LANDING_PAGE, BOOK_PUJA_PARTICIPATE_NOW_PARTICULAR_PAGE, BOOK_PUJA_PARTICIPATE_NOW_SELECT_PACKAGE_PARTICULAR_PAGE, SEND_OTP, BOOKING_CHECKOUT_BUTTON, PAYMENT_STATUS, OFFER_CHADAWA_PARTICIPATE_LANDING_PAGE, BOOK_CHADAWA_PARTICIPATE_NOW_PARTICULAR_PAGE , COMPLETE_REGISTRATION};