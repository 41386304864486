import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: [],
};

const cart = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartItems: (state, action) => {
      state.cartItems = action.payload;
    },
    incrementItemCount: (state, action) => {
      const { id, amount, itemName, imageInfo, chadawaId } = action.payload;

      // Check if cart has items and if all items have a different chadawaId
      const hasDifferentChadawa =
        state.cartItems.length > 0 &&
        !state.cartItems.some((item) => item.chadawaId === chadawaId);

      // If there are items with different chadawaId, clear the cart first
      if (hasDifferentChadawa) {
        state.cartItems = [];
      }

      // Now proceed with adding the item
      const itemExists = state.cartItems.find(
        (item) => item.id === id && item.chadawaId === chadawaId
      );

      if (itemExists) {
        itemExists.count += 1;
      } else {
        state.cartItems.push({
          id,
          amount,
          itemName,
          imageInfo,
          chadawaId,
          count: 1,
        });
      }
    },
    decrementItemCount: (state, action) => {
      const { id, chadawaId } = action.payload;

      const itemIndex = state.cartItems.findIndex(
        (item) => item.id === id && item.chadawaId === chadawaId
      );

      if (itemIndex !== -1) {
        const item = state.cartItems[itemIndex];
        if (item.count > 1) {
          item.count -= 1;
        } else {
          state.cartItems.splice(itemIndex, 1);
        }
      }
    },
    emptyCart: (state) => {
      state.cartItems = []; // Just empty the cart
    },
  },
});

export const {
  setCartItems,
  incrementItemCount,
  decrementItemCount,
  emptyCart,
} = cart.actions;
export default cart.reducer;
