import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

const ParticularChadawaHeader = ({ pooja }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className="px-8">
        <div className="text-sm pt-4 lg:px-6 text-gray-600 mt-16 font-poppins font-bold flex items-center">
          {/* <button onClick={handleBack} className="text-custom-brown flex items-center">
            <ArrowBack className="w-4 h-4 mr-2" />
            <span>Back</span>
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default ParticularChadawaHeader;
