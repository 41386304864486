import React, { use, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HeroSection from "./pages/homePage";
import Footer from "./components/footer";
import ChadawaOfferings from "./pages/Chadawa";
import ParticularChadawa from "./pages/ParticularChadawa";
import Pujalistingpage from "./pages/pujalistingpage";
import ParticularPuja from "./pages/particularpuja";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./components/Common/navbar";
import { VisibilityProvider } from "./devsathan_components/VisibilityContext";
import { ScrollProvider } from "./devsathan_components/ScrollContext";
import PlacedOrdersPage from "./pages/placedOrders";
import OrderDetailsPage from "./pages/orderDetails";
import Auth from "./pages/Auth";
import PaymentProcessing from "./pages/PaymentProcessing";
import Paymentsuccess from "../src/components/Payment/payment_success";
import Paymentfailure from "../src/components/Payment/payment_failure";
import UserProfile from "./pages/UserProfile";
import TagManager from "react-gtm-module";
import { useLocation } from "react-router-dom";
import AuthModal from "../src/components/Auth/AuthModal";
import { useSelector } from "react-redux";
import { openModal } from "./utils/openModal";
import CommonCart from "./pages/CommonCart";
import { loadToken } from "./utils/localStorage";
import { apiRequest } from "./utils/apiRequest";
import { GETUSERDATA } from "./rest/endpoints";
import { getAuthHeaders } from "./utils/getHeaders";
import { generalloadToken } from "./utils/localStorage";
import { generalsaveToken } from "./utils/localStorage";
import ScrollToTop from "./components/ScrollToTop";

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        page: location.pathname + location.search, // Tracks the current URL
      },
    });
  }, [location]);

  return null;
};
const generateRandomGuestId = () => {
  return (
    "GUEST_" +
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
};
const FetchedUserId = () => {
  useEffect(() => {
    const fetchUserId = async () => {
      const token = loadToken();
      const existingGuestId = generalloadToken("guestId");
      const existingUserId = generalloadToken("userId");

      if (token && !existingUserId) {
        const fetchedUserId = await apiRequest({
          url: GETUSERDATA,
          method: "GET",
          headers: getAuthHeaders(),
        });
        const userId = fetchedUserId.data.userId;
        console.log("fetchedUserId", userId);
        generalsaveToken("userId", userId);
      } else if (!token && !existingGuestId) {
        console.log("generating guest id");
        generalsaveToken("guestId", generateRandomGuestId());
      }
    };
    fetchUserId();
  }, [useLocation().pathname]);
};

function App() {
  const showModal = useSelector((state) => state.showModal.showModal);

  return (
    // <div id="google_translate_element">

    <ScrollProvider>
      <VisibilityProvider>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <Router>
            <AuthModal
              isOpen={showModal}
              onClose={() => openModal({ value: false })}
              message="Please Login"
            />
            <ScrollToTop />
            <TrackPageView />
            <FetchedUserId />
            <Navbar />
            <Routes>
              <Route path="/" element={<HeroSection />} />
              <Route path="/chadawa" element={<ChadawaOfferings />} />
              <Route path="/chadawa/:id" element={<ParticularChadawa />} />
              <Route path="/puja-listing" element={<Pujalistingpage />} />
              <Route path="/puja/:id" element={<ParticularPuja />} />
              <Route path="/puja/1" element={<ParticularPuja />} />
              <Route path="/auth" element={<Auth />} />
              <Route path="/cart" element={<CommonCart />} />
              <Route
                path="/payment/processing"
                element={<PaymentProcessing />}
              />
              <Route path="/payment/success" element={<Paymentsuccess />} />
              <Route path="/payment/failure" element={<Paymentfailure />} />
              <Route path="/orders" element={<PlacedOrdersPage />} />
              <Route path="/orders/:id" element={<OrderDetailsPage />} />
              <Route path="/profile" element={<UserProfile />} />
            </Routes>
            <Footer />
            <ToastContainer />
          </Router>
        </div>
      </VisibilityProvider>
    </ScrollProvider>
    // </div>
  );
}

export default App;
