import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedPackage: "Individual", // Default package
};

const pujaPackageSlice = createSlice({
  name: "pujaPackage",
  initialState,
  reducers: {
    setPackage: (state, action) => {
      state.selectedPackage = action.payload;
    },
  },
});

export const { setPackage } = pujaPackageSlice.actions;
export default pujaPackageSlice.reducer;
