import { useEffect, useState } from "react";

const useDetectStorageClear = () => {
  const [reloadKey, setReloadKey] = useState(0); // State to force re-render

  useEffect(() => {
    const sessionKey = "sessionMarker";

    // Store a session key on mount
    if (!localStorage.getItem(sessionKey)) {
      localStorage.setItem(sessionKey, "active");
    }

    // Function to re-render the component
    const triggerReload = () => {
      setReloadKey((prev) => prev + 1);
    };

    // Detect when localStorage is cleared in another tab
    const handleStorageChange = (event) => {
      if (event.key === null) {
        triggerReload();
      }
    };

    // Detect when the tab comes into focus
    const handleVisibilityChange = () => {
      if (
        document.visibilityState === "visible" &&
        !localStorage.getItem(sessionKey)
      ) {
        triggerReload();
      }
    };

    // Override localStorage.clear() to detect manual clearing in the same tab
    const originalClear = localStorage.clear;
    localStorage.clear = function () {
      originalClear.apply(this, arguments);
      window.dispatchEvent(new Event("localStorageCleared"));
    };

    // Override localStorage.removeItem() for specific key removals
    const originalRemoveItem = localStorage.removeItem;
    localStorage.removeItem = function (key) {
      originalRemoveItem.apply(this, arguments);
      if (key === sessionKey) {
        window.dispatchEvent(new Event("localStorageCleared"));
      }
    };

    // Listen for events
    window.addEventListener("storage", handleStorageChange);
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("localStorageCleared", triggerReload);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("localStorageCleared", triggerReload);
      localStorage.clear = originalClear; // Restore original functions
      localStorage.removeItem = originalRemoveItem;
    };
  }, []);

  return reloadKey; // Return a key to force component re-render
};

export default useDetectStorageClear;
