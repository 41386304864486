import React from "react";

import InformationSection from "../Common/InformationSection";
import ImageSection from "../Common/ImageSection_courosel";

const AboutChadawa = ({ particularChadawa }) => {
  console.log();

  return (
    <div className="bg-inherit sm:px-20 px-10 pb-10 -mt-16">
      <div className="">
        <h1 className="text-3xl md:text-4xl lg:text-4xl text-custom-maroon font-semibold pt-28 pb-6 text-center">
          About Chadawa
        </h1>
        <div className="grid lg:grid-cols-2 gap-4">
          <div>
            <ImageSection imagesa={particularChadawa.imageInfoList} />
            <p className="mt-4 text-gray-700 md:text-lg lg:w-[52vw] font-poppins text-xs">
              {particularChadawa.description}
            </p>
          </div>
          <InformationSection />
        </div>
      </div>
    </div>
  );
};

export default AboutChadawa;
