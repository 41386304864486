import React from "react";

import DateSection from "../Cards/offeringCardComponents/DateSection";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import ImageSection from "../Common/ImageSection_courosel";

const Details = ({ particularChadawa }) => {
  const location = particularChadawa.location || {};
  // const titleImageInfo = particularChadawa.titleImageInfo || {};

  return (
    <div className="bg-inherit p-6 rounded-lg shadow-md mx-auto pb-10 sm:pb-24">
      <div className="flex flex-col md:flex-row items-start justify-start">
        {/* Image Section */}
        <div className="pl-4 sm:pl-14 sm:pr-16 mb-2">
          {/* <img
            // src="https://images.pexels.com/photos/443446/pexels-photo-443446.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            src={titleImageInfo.url}
            alt={titleImageInfo.altText}
            className="rounded-lg w-[80vw] h-[55vw] mx-auto md:h-[25vw] lg:w-[52vw] lg:h-[28vw]"
          /> */}

          <ImageSection imagesa={particularChadawa?.imageInfoList} />

        </div>

        {/* Content Section */}
        <div className="px-4 sm:max-w-[40vw] flex flex-col items pt-4 pr-8">
          <DateSection offering={particularChadawa} />

          <h2 className="text-[clamp(1.25rem,4vw,2rem)] md:text-[1.8vw] lg:text-4xl text-custom-maroon font-poppins font-semibold sm:mt-6 mt-4 leading-[1.3]">
            {particularChadawa.name}
          </h2>

          <p className="text-md md:text-[1vw] lg:text-base text-custom-brown mb-3 mt-4 xs:text-xs">
            {particularChadawa.shortDescription}
          </p>

          <div className="flex items-center text-gray-600">
            <div className="pr-2 text-custom-gray">
              <FmdGoodOutlinedIcon />
            </div>
            <h1 className="font-poppins font-medium text-sm text-custom-light-brown">
              <span className="pr-2">{location.address}</span>
              <span>{location.pincode}</span>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
